export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A timestamp in ISO 8601 format */
  Date: { input: Date; output: Date };
};

export type ActiveCampaignApi = {
  __typename?: 'ActiveCampaignApi';
  tag: ActiveCampaignTag;
  tags: ActiveCampaignApiTagsList;
};

export type ActiveCampaignApiTagArgs = {
  id: Scalars['String']['input'];
};

export type ActiveCampaignApiTagsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ActiveCampaignApiTagsList = ListResult & {
  __typename?: 'ActiveCampaignApiTagsList';
  limit: Scalars['Int']['output'];
  tags: Array<ActiveCampaignTag>;
  totalCount: Scalars['Int']['output'];
};

export type ActiveCampaignTag = {
  __typename?: 'ActiveCampaignTag';
  id: Scalars['String']['output'];
  subscriberCount: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
};

export type ActiveCampaignTagsSyncConfig = {
  __typename?: 'ActiveCampaignTagsSyncConfig';
  activeCampaignTagId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  subscriptionPlanIds: Array<Scalars['String']['output']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type Agreement = {
  __typename?: 'Agreement';
  agreementType: AgreementType;
  id: Scalars['String']['output'];
  publishTimestamp: Scalars['Date']['output'];
  text: Scalars['String']['output'];
};

export const AgreementType = {
  PrivacyPolicy: 'PrivacyPolicy',
  TermsOfUse: 'TermsOfUse',
} as const;

export type AgreementType = (typeof AgreementType)[keyof typeof AgreementType];
export type ApiToken = {
  __typename?: 'ApiToken';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  invalidatedAt?: Maybe<Scalars['Date']['output']>;
  name: Scalars['String']['output'];
};

export type ApiTokensList = ListResult & {
  __typename?: 'ApiTokensList';
  apiTokens: Array<ApiToken>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ApplyCustomPromoCodeResult = ApplyCustomPromoCodeSuccessResult | GenericInternalError;

export type ApplyCustomPromoCodeSuccessResult = {
  __typename?: 'ApplyCustomPromoCodeSuccessResult';
  promoCode: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  successMessage?: Maybe<Delta>;
  valid: Scalars['Boolean']['output'];
};

export type ArchiveComponentResult = ArchiveComponentSuccessResult | GenericUserError;

export type ArchiveComponentSuccessResult = {
  __typename?: 'ArchiveComponentSuccessResult';
  component: Component;
};

export type ColorHsl = {
  __typename?: 'ColorHsl';
  h: Scalars['String']['output'];
  l: Scalars['String']['output'];
  s: Scalars['String']['output'];
};

export type CompletedStagesChangedResult = {
  __typename?: 'CompletedStagesChangedResult';
  id: Scalars['String']['output'];
};

export type Component = {
  __typename?: 'Component';
  archived: Scalars['Boolean']['output'];
  components: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewProps?: Maybe<Scalars['String']['output']>;
  propsSetupComponents?: Maybe<Scalars['String']['output']>;
};

export type ComponentsList = ListResult & {
  __typename?: 'ComponentsList';
  components: Array<Component>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ComponentsUpdate = {
  value: Scalars['String']['input'];
};

export const ConfirmationAction = {
  /** Display only a message. */
  Message: 'Message',
  /** Display a message and keep the form as is. */
  MessageKeepForm: 'MessageKeepForm',
  /** Redirect to a URL. */
  Redirect: 'Redirect',
} as const;

export type ConfirmationAction = (typeof ConfirmationAction)[keyof typeof ConfirmationAction];
export type ContentPlan = Course | Sequence;

export type ContentPlansList = ListResult & {
  __typename?: 'ContentPlansList';
  contentPlans: Array<ContentPlan>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Course = {
  __typename?: 'Course';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notes: Array<UserTakeaways>;
  /** All of the Course's StageBlueprints, which for authenticated users includes deleted StageBlueprints by default. */
  stageBlueprints: Array<StageBlueprint>;
};

export type CourseNotesArgs = {
  userId: Scalars['String']['input'];
};

export type CourseStageBlueprintsArgs = {
  status?: InputMaybe<StageBlueprintStatus>;
};

export type CourseSubLesson = {
  __typename?: 'CourseSubLesson';
  heading: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type CoursesList = ListResult & {
  __typename?: 'CoursesList';
  courses: Array<Course>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CreateApiTokenInput = {
  name: Scalars['String']['input'];
};

export type CreateApiTokenResult = MutationResult & {
  __typename?: 'CreateApiTokenResult';
  apiToken?: Maybe<ApiToken>;
  success: Scalars['Boolean']['output'];
  /** A JWT. */
  token?: Maybe<Scalars['String']['output']>;
  userErrors?: Maybe<Array<UserError>>;
};

export type CreateComponentInput = {
  components?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type CreateComponentResult = CreateComponentSuccessResult | GenericUserError;

export type CreateComponentSuccessResult = {
  __typename?: 'CreateComponentSuccessResult';
  component: Component;
};

export type CreateCourseResult = CreateCourseSuccessResult | GenericUserError;

export type CreateCourseSuccessResult = {
  __typename?: 'CreateCourseSuccessResult';
  course: Course;
};

export type CreateLayoutInput = {
  components: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pathPrefix: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type CreateLayoutResult = CreateLayoutSuccessResult | GenericUserError;

export type CreateLayoutSuccessResult = {
  __typename?: 'CreateLayoutSuccessResult';
  layout: Layout;
};

export type CreatePagePurposeResult = CreatePagePurposeSuccessResult | GenericUserError;

export type CreatePagePurposeSuccessResult = {
  __typename?: 'CreatePagePurposeSuccessResult';
  pagePurpose: PagePurpose;
};

export type CreatePagesFromTemplatePurposeResult =
  | CreatePagesFromTemplatePurposeSuccessResult
  | GenericUserError;

export type CreatePagesFromTemplatePurposeSuccessResult = {
  __typename?: 'CreatePagesFromTemplatePurposeSuccessResult';
  pages: Array<Page>;
};

export type CreateProductResult = CreateProductSuccessResult | GenericUserError;

export type CreateProductSuccessResult = {
  __typename?: 'CreateProductSuccessResult';
  product: SubscriptionPlan;
};

export type CreateSequenceResult = CreateSequenceSuccessResult | GenericUserError;

export type CreateSequenceSuccessResult = {
  __typename?: 'CreateSequenceSuccessResult';
  sequence: Sequence;
};

export type CustomField = {
  __typename?: 'CustomField';
  /** A JSON value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  deployStage: CustomFieldDeployStage;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  indexed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencesObject?: Maybe<CustomTableObject>;
  referencesTarget?: Maybe<CustomTableTarget>;
  required: Scalars['Boolean']['output'];
  requiredValid: Scalars['Boolean']['output'];
  type: CustomFieldType;
  unique: Scalars['Boolean']['output'];
};

export const CustomFieldDeployStage = {
  Create: 'Create',
  Live: 'Live',
  Remove: 'Remove',
} as const;

export type CustomFieldDeployStage =
  (typeof CustomFieldDeployStage)[keyof typeof CustomFieldDeployStage];
export type CustomFieldInput = {
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  indexed: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  referencesObject?: InputMaybe<CustomTableObject>;
  required: Scalars['Boolean']['input'];
  type: CustomFieldType;
  unique: Scalars['Boolean']['input'];
};

export const CustomFieldType = {
  Boolean: 'Boolean',
  CustomFieldId: 'CustomFieldId',
  /** A plain date. */
  Date: 'Date',
  /** A plain date and a plain time combined. */
  DateTime: 'DateTime',
  Float: 'Float',
  FormId: 'FormId',
  FormTemplateId: 'FormTemplateId',
  Instant: 'Instant',
  Int: 'Int',
  MixerComponents: 'MixerComponents',
  OrgId: 'OrgId',
  PageId: 'PageId',
  PageTemplateId: 'PageTemplateId',
  ProductId: 'ProductId',
  RichText: 'RichText',
  SiteId: 'SiteId',
  String: 'String',
  SubscriptionId: 'SubscriptionId',
  UserId: 'UserId',
} as const;

export type CustomFieldType = (typeof CustomFieldType)[keyof typeof CustomFieldType];
export type CustomFieldVersionsList = ListResult & {
  __typename?: 'CustomFieldVersionsList';
  customFields: Array<CustomField>;
  /** Defaults to 10,000. Limit is capped at 10,000. */
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomFieldsList = ListResult & {
  __typename?: 'CustomFieldsList';
  customFields: Array<CustomField>;
  /** Defaults to 10,000. Limit is capped at 10,000. */
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomTable = {
  __typename?: 'CustomTable';
  fields: CustomFieldsList;
  id: Scalars['String']['output'];
  object?: Maybe<CustomTableObject>;
  target?: Maybe<CustomTableTarget>;
  /** The table's versions, sorted descending by createdAt. */
  versions: CustomTableVersionsList;
};

export type CustomTableFieldsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomTableVersionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export const CustomTableObject = {
  Form: 'Form',
  FormTemplate: 'FormTemplate',
  Org: 'Org',
  Page: 'Page',
  PageTemplate: 'PageTemplate',
  Product: 'Product',
  Site: 'Site',
  Subscription: 'Subscription',
  User: 'User',
} as const;

export type CustomTableObject = (typeof CustomTableObject)[keyof typeof CustomTableObject];
export const CustomTableTarget = {
  Form: 'Form',
  FormTemplate: 'FormTemplate',
  Org: 'Org',
  Page: 'Page',
  PageTemplate: 'PageTemplate',
  Product: 'Product',
  Site: 'Site',
  Subscription: 'Subscription',
  User: 'User',
} as const;

export type CustomTableTarget = (typeof CustomTableTarget)[keyof typeof CustomTableTarget];
export type CustomTableVersion = {
  __typename?: 'CustomTableVersion';
  byUser: UserProfile;
  createdAt: Scalars['Date']['output'];
  deployCanceledAt?: Maybe<Scalars['Date']['output']>;
  deployPublishedAt?: Maybe<Scalars['Date']['output']>;
  deployStage: CustomTableVersionDeployStage;
  fields: CustomTableVersionFieldsList;
  id: Scalars['String']['output'];
};

export type CustomTableVersionFieldsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export const CustomTableVersionDeployStage = {
  Backfill: 'Backfill',
  Create: 'Create',
  Publish: 'Publish',
  Validate: 'Validate',
} as const;

export type CustomTableVersionDeployStage =
  (typeof CustomTableVersionDeployStage)[keyof typeof CustomTableVersionDeployStage];
export type CustomTableVersionField = {
  __typename?: 'CustomTableVersionField';
  /** A JSON value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  indexed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencesObject?: Maybe<CustomTableObject>;
  referencesTarget?: Maybe<CustomTableTarget>;
  required: Scalars['Boolean']['output'];
  type: CustomFieldType;
  unique: Scalars['Boolean']['output'];
};

export type CustomTableVersionFieldsList = ListResult & {
  __typename?: 'CustomTableVersionFieldsList';
  customTableVersionFields: Array<CustomTableVersionField>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomTableVersionsList = ListResult & {
  __typename?: 'CustomTableVersionsList';
  customTableVersions: Array<CustomTableVersion>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomTablesList = ListResult & {
  __typename?: 'CustomTablesList';
  customTables: Array<CustomTable>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Delta = {
  __typename?: 'Delta';
  /** A JSON object */
  delta: Scalars['String']['output'];
};

export type DeltaInput = {
  /** A JSON object */
  delta: Scalars['String']['input'];
};

export type DistrictDashboard = {
  __typename?: 'DistrictDashboard';
  activeUsers: Array<User>;
  newUsers: Array<User>;
};

export type Document = {
  __typename?: 'Document';
  currentVersion?: Maybe<File>;
  downloadPrompt?: Maybe<DocumentDownloadPrompt>;
  /** @deprecated Use productItem instead. */
  featureTag?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  productItem?: Maybe<Scalars['String']['output']>;
  roles: Array<Role>;
  stamp: Scalars['Boolean']['output'];
  status: PublishStatus;
  /** All of the document's versions. Accessible only to users with Permission.View on the document's platform. */
  versions: Array<File>;
};

export type DocumentDownloadPrompt = {
  __typename?: 'DocumentDownloadPrompt';
  acceptLabel: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  prompt: Delta;
  promptHeader: Scalars['String']['output'];
};

export type DocumentsList = ListResult & {
  __typename?: 'DocumentsList';
  documents: Array<Document>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type DuplicateComponentInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type DuplicateComponentResult =
  | DuplicateComponentSuccessResult
  | GenericInternalError
  | GenericUserError;

export type DuplicateComponentSuccessResult = {
  __typename?: 'DuplicateComponentSuccessResult';
  component: Component;
};

export type DuplicateCourseResult = DuplicateCourseSuccessResult | GenericUserError;

export type DuplicateCourseSuccessResult = {
  __typename?: 'DuplicateCourseSuccessResult';
  course: Course;
};

export type DuplicateSequenceResult = DuplicateSequenceSuccessResult | GenericUserError;

export type DuplicateSequenceSuccessResult = {
  __typename?: 'DuplicateSequenceSuccessResult';
  sequence: Sequence;
};

export type EditComponentInput = {
  components?: InputMaybe<OptionalComponentsUpdate>;
  id: Scalars['String']['input'];
  name?: InputMaybe<OptionalStringUpdate>;
  previewProps?: InputMaybe<OptionalExpressionDataUpdate>;
  propsSetupComponents?: InputMaybe<OptionalComponentsUpdate>;
};

export type EditComponentResult = EditComponentSuccessResult | GenericUserError;

export type EditComponentSuccessResult = {
  __typename?: 'EditComponentSuccessResult';
  component: Component;
};

export type EditCourseResult = EditCourseSuccessResult | GenericUserError;

export type EditCourseSuccessResult = {
  __typename?: 'EditCourseSuccessResult';
  course: Course;
};

export type EditCustomTableInput = {
  /**
   * When there is already a version of the table, this field must reference the latest version of
   * the table to ensure that the client is aware of the latest schema. This way the client will not
   * accidentally overwrite changes made asynchronously.
   */
  consistencyKeyCurrentTableVersionId?: InputMaybe<Scalars['String']['input']>;
  fields: Array<CustomFieldInput>;
  object: CustomTableTarget;
  platformId: Scalars['String']['input'];
};

export type EditCustomTableResult = EditCustomTableSuccessResult | GenericUserError;

export type EditCustomTableSuccessResult = {
  __typename?: 'EditCustomTableSuccessResult';
  customTable: CustomTable;
};

export type EditFormTemplateEmailTemplateInput = {
  emailBodyTemplate?: InputMaybe<OptionalStringUpdate>;
  formTemplateId: Scalars['String']['input'];
  useEmailBodyTemplate: Scalars['Boolean']['input'];
};

export type EditFormTemplateEmailTemplateResult =
  | EditFormTemplateEmailTemplateSuccessResult
  | GenericUserError;

export type EditFormTemplateEmailTemplateSuccessResult = {
  __typename?: 'EditFormTemplateEmailTemplateSuccessResult';
  formTemplate: FormTemplate;
};

export type EditFormTemplateFormattedDataTemplateInput = {
  formTemplateId: Scalars['String']['input'];
  formattedDataTemplate?: InputMaybe<OptionalStringUpdate>;
  useFormattedDataTemplate: Scalars['Boolean']['input'];
};

export type EditFormTemplateFormattedDataTemplateResult =
  | EditFormTemplateFormattedDataTemplateSuccessResult
  | GenericUserError;

export type EditFormTemplateFormattedDataTemplateSuccessResult = {
  __typename?: 'EditFormTemplateFormattedDataTemplateSuccessResult';
  formTemplate: FormTemplate;
};

export type EditLayoutInput = {
  components?: InputMaybe<ComponentsUpdate>;
  id: Scalars['String']['input'];
  name?: InputMaybe<StringUpdate>;
  pathPrefix?: InputMaybe<StringUpdate>;
};

export type EditLayoutResult = EditLayoutSuccessResult | GenericUserError;

export type EditLayoutSuccessResult = {
  __typename?: 'EditLayoutSuccessResult';
  layout: Layout;
};

export type EditOrgPrimaryUserResult = EditOrgPrimaryUserSuccessResult | GenericUserError;

export type EditOrgPrimaryUserSuccessResult = {
  __typename?: 'EditOrgPrimaryUserSuccessResult';
  org: Organization;
  previousPrimaryUser?: Maybe<User>;
};

export type EditPagePurposeResult = EditPagePurposeSuccessResult | GenericUserError;

export type EditPagePurposeSuccessResult = {
  __typename?: 'EditPagePurposeSuccessResult';
  pagePurpose: PagePurpose;
};

export type EditPageTemplateInput = {
  bodyStyles: Scalars['String']['input'];
  components?: InputMaybe<OptionalComponentsUpdate>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  metaTitle: Scalars['String']['input'];
  pagePurposeId?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  previewImageUrl?: InputMaybe<Scalars['String']['input']>;
  previewProps?: InputMaybe<OptionalExpressionDataUpdate>;
  propsSetupComponents?: InputMaybe<OptionalComponentsUpdate>;
  status: PublishStatus;
  title: Scalars['String']['input'];
};

export type EditPageTemplateResult = EditPageTemplateSuccessResult | GenericUserError;

export type EditPageTemplateSuccessResult = {
  __typename?: 'EditPageTemplateSuccessResult';
  pageTemplate: PageTemplate;
};

export type EditPlatformInput = {
  emailReplyToAddress?: InputMaybe<OptionalStringUpdate>;
  emailSendFromAddress?: InputMaybe<StringUpdate>;
  id: Scalars['String']['input'];
  sendGridApiKey?: InputMaybe<OptionalStringUpdate>;
  stripeSecretKey?: InputMaybe<OptionalStringUpdate>;
  stripeWebhookSigningSecret?: InputMaybe<OptionalStringUpdate>;
};

export type EditPlatformResult = EditPlatformSuccessResult | GenericUserError;

export type EditPlatformSuccessResult = {
  __typename?: 'EditPlatformSuccessResult';
  platform: Platform;
};

export type EditProductResult = EditProductSuccessResult | GenericUserError;

export type EditProductSuccessResult = {
  __typename?: 'EditProductSuccessResult';
  product: SubscriptionPlan;
};

export type EditSequenceResult = EditSequenceSuccessResult | GenericUserError;

export type EditSequenceSuccessResult = {
  __typename?: 'EditSequenceSuccessResult';
  sequence: Sequence;
};

export type EditUserInput = {
  expiresAt?: InputMaybe<OptionalDateUpdate>;
  id: Scalars['String']['input'];
};

export type EditUserResult = EditUserSuccessResult | GenericUserError;

export type EditUserSuccessResult = {
  __typename?: 'EditUserSuccessResult';
  user: User;
};

export type Extension = {
  __typename?: 'Extension';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ExtensionsList = ListResult & {
  __typename?: 'ExtensionsList';
  extensions: Array<Extension>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type FeatureTag = {
  __typename?: 'FeatureTag';
  id: Scalars['String']['output'];
  tag: Scalars['String']['output'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['Date']['output'];
  downloadName: Scalars['String']['output'];
  getUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  status: FileStatus;
};

export type FileGetUrlArgs = {
  download: Scalars['Boolean']['input'];
};

export const FileStatus = {
  Canceled: 'Canceled',
  Deleted: 'Deleted',
  Uploaded: 'Uploaded',
  Uploading: 'Uploading',
} as const;

export type FileStatus = (typeof FileStatus)[keyof typeof FileStatus];
export const FileUploadFeature = {
  Document: 'Document',
  SiteAgentPhotoUrl: 'SiteAgentPhotoUrl',
  SiteLogoUrl: 'SiteLogoUrl',
  StageState: 'StageState',
} as const;

export type FileUploadFeature = (typeof FileUploadFeature)[keyof typeof FileUploadFeature];
export type FileUploadResult = {
  __typename?: 'FileUploadResult';
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type FindOrCreateStripeSubscriptionActiveResult = {
  __typename?: 'FindOrCreateStripeSubscriptionActiveResult';
  id: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
};

export type FindOrCreateStripeSubscriptionErrorResult = {
  __typename?: 'FindOrCreateStripeSubscriptionErrorResult';
  message: Scalars['String']['output'];
};

export type FindOrCreateStripeSubscriptionIncompleteResult = {
  __typename?: 'FindOrCreateStripeSubscriptionIncompleteResult';
  id: Scalars['String']['output'];
  paymentIntentClientSecret: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
};

export type FindOrCreateStripeSubscriptionResult =
  | FindOrCreateStripeSubscriptionActiveResult
  | FindOrCreateStripeSubscriptionErrorResult
  | FindOrCreateStripeSubscriptionIncompleteResult
  | FindOrCreateStripeSubscriptionUpdatedResult;

export type FindOrCreateStripeSubscriptionUpdatedResult = {
  __typename?: 'FindOrCreateStripeSubscriptionUpdatedResult';
  subscriptionPlanId: Scalars['String']['output'];
};

export type Form = {
  __typename?: 'Form';
  components: Scalars['String']['output'];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: Maybe<Scalars['String']['output']>;
  confirmationRedirectParameters: Array<FormConfirmationRedirectUrlParameter>;
  confirmationRedirectUrl?: Maybe<Scalars['String']['output']>;
  emailBodyTemplate?: Maybe<Scalars['String']['output']>;
  entriesTotalCount: Scalars['Int']['output'];
  formattedDataTemplate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  steps: Array<FormStep>;
  submissionActions: Array<FormSubmissionAction>;
  title: Scalars['String']['output'];
  useEmailBodyTemplate: Scalars['Boolean']['output'];
  useFormattedDataTemplate: Scalars['Boolean']['output'];
};

export type FormConfirmationRedirectUrlParameter = {
  __typename?: 'FormConfirmationRedirectUrlParameter';
  componentName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  parameter: Scalars['String']['output'];
};

export type FormConfirmationRedirectUrlParameterInput = {
  componentName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  parameter: Scalars['String']['input'];
};

export type FormEntriesList = ListResult & {
  __typename?: 'FormEntriesList';
  entries: Array<FormEntry>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type FormEntry = {
  __typename?: 'FormEntry';
  createdAt: Scalars['Date']['output'];
  formData: Scalars['String']['output'];
  formId: Scalars['String']['output'];
  formTitle: Scalars['String']['output'];
  id: Scalars['String']['output'];
  submissionPageUrl: Scalars['String']['output'];
  user?: Maybe<UserProfile>;
};

export type FormStep = {
  __typename?: 'FormStep';
  components: Scalars['String']['output'];
  edited: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['String']['output'];
};

export type FormSubmissionAction = {
  __typename?: 'FormSubmissionAction';
  createdAt: Scalars['Date']['output'];
  emailAddress?: Maybe<Scalars['String']['output']>;
  emailSubject?: Maybe<Scalars['String']['output']>;
  formId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  type: FormSubmissionActionType;
  webhookUrl?: Maybe<Scalars['String']['output']>;
};

export const FormSubmissionActionType = {
  Email: 'Email',
  Webhook: 'Webhook',
} as const;

export type FormSubmissionActionType =
  (typeof FormSubmissionActionType)[keyof typeof FormSubmissionActionType];
export type FormTemplate = {
  __typename?: 'FormTemplate';
  components: Scalars['String']['output'];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: Maybe<Scalars['String']['output']>;
  confirmationRedirectParameters: Array<FormConfirmationRedirectUrlParameter>;
  confirmationRedirectUrl?: Maybe<Scalars['String']['output']>;
  emailBodyTemplate?: Maybe<Scalars['String']['output']>;
  formattedDataTemplate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: PublishStatus;
  steps: Array<FormTemplateStep>;
  title: Scalars['String']['output'];
  useEmailBodyTemplate: Scalars['Boolean']['output'];
  useFormattedDataTemplate: Scalars['Boolean']['output'];
};

export type FormTemplateStep = {
  __typename?: 'FormTemplateStep';
  components: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['String']['output'];
};

export const GCloudCertificateMapEntryState = {
  Active: 'Active',
  Pending: 'Pending',
  Unknown: 'Unknown',
} as const;

export type GCloudCertificateMapEntryState =
  (typeof GCloudCertificateMapEntryState)[keyof typeof GCloudCertificateMapEntryState];
export const GCloudCertificateState = {
  Active: 'Active',
  Failed: 'Failed',
  Provisioning: 'Provisioning',
  Unknown: 'Unknown',
} as const;

export type GCloudCertificateState =
  (typeof GCloudCertificateState)[keyof typeof GCloudCertificateState];
export type GenericInternalError = InternalError & {
  __typename?: 'GenericInternalError';
  message: Scalars['String']['output'];
};

export type GenericUserError = UserError & {
  __typename?: 'GenericUserError';
  message: Scalars['String']['output'];
};

export type HelpTicket = {
  __typename?: 'HelpTicket';
  createdAt: Scalars['Date']['output'];
  customDomain?: Maybe<Scalars['String']['output']>;
  customSiteBodyEndCode?: Maybe<Scalars['String']['output']>;
  customSiteBodyStartCode?: Maybe<Scalars['String']['output']>;
  customSiteHeaderCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  status: HelpTicketStatus;
  type: Scalars['String']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
  zendeskTicketId?: Maybe<Scalars['String']['output']>;
};

export const HelpTicketStatus = {
  Closed: 'Closed',
  Open: 'Open',
} as const;

export type HelpTicketStatus = (typeof HelpTicketStatus)[keyof typeof HelpTicketStatus];
export type InitialFormData = {
  __typename?: 'InitialFormData';
  formData?: Maybe<Scalars['String']['output']>;
};

export type InternalError = {
  /** A user-friendly error message. */
  message: Scalars['String']['output'];
};

export type InvalidateApiTokenResult = MutationResult & {
  __typename?: 'InvalidateApiTokenResult';
  apiToken?: Maybe<ApiToken>;
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export const InvoiceFrequency = {
  Annually: 'Annually',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  SemiAnnually: 'SemiAnnually',
} as const;

export type InvoiceFrequency = (typeof InvoiceFrequency)[keyof typeof InvoiceFrequency];
export type Layout = {
  __typename?: 'Layout';
  components: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pathPrefix: Scalars['String']['output'];
};

export type LayoutsList = ListResult & {
  __typename?: 'LayoutsList';
  layouts: Array<Layout>;
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ListResult = {
  limit: Scalars['Int']['output'];
  /** The total number of items in the list. */
  totalCount: Scalars['Int']['output'];
};

export type MoveUserToOrgResult = GenericUserError | MoveUserToOrgSuccessResult;

export type MoveUserToOrgSuccessResult = {
  __typename?: 'MoveUserToOrgSuccessResult';
  sourceOrg: Organization;
  targetOrg: Organization;
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateSpecialPage: Page;
  archiveComponent: ArchiveComponentResult;
  changePassword: Scalars['Boolean']['output'];
  consentToAgreement: User;
  createApiToken: CreateApiTokenResult;
  createComponent: CreateComponentResult;
  createCourse: CreateCourseResult;
  createDocument: Document;
  createDocumentDownloadPrompt: DocumentDownloadPrompt;
  createFeatureTag: Scalars['String']['output'];
  createForm: Form;
  createFormStep: FormStep;
  createFormSubmissionAction: FormSubmissionAction;
  createFormTemplate: FormTemplate;
  createFormTemplateStep: FormTemplateStep;
  createHelpTicket: HelpTicket;
  createLayout: CreateLayoutResult;
  createPage: Page;
  createPageFromTemplate: Page;
  createPagePurpose: CreatePagePurposeResult;
  createPageTemplate: PageTemplate;
  createPagesFromTemplatePurpose: CreatePagesFromTemplatePurposeResult;
  createProduct: CreateProductResult;
  createSequence: CreateSequenceResult;
  createSite: Site;
  createStage: Stage;
  createStageBlueprint: StageBlueprint;
  createSubscription: SubscriptionOrganization;
  createSubscriptionOrganization: SubscriptionOrganization;
  createTlsCertificate: TlsCertificate;
  createTlsCertificateMapEntry: TlsCertificate;
  /**
   * To create a user in an organization that belongs to one's platform, the requester must have Permission.CreateUser.
   * The request may specify the role (assuming the requester has Permission.EditUserRole) and status (assuming the
   * requester has Permission.EditUserStatus) for the new user
   *
   * When adding a user to one's own organization, the requester must have OrganizationPermission.Edit in their org.
   * Neither a role nor a status may be specified in this case.
   */
  createUser: User;
  deleteFeatureTag: Scalars['Boolean']['output'];
  deleteFormStep: Scalars['Boolean']['output'];
  deleteFormSubmissionAction: Scalars['Boolean']['output'];
  deleteFormTemplateStep: Scalars['Boolean']['output'];
  deleteStageBlueprint: Scalars['Boolean']['output'];
  deleteSubscriptionOrganization: Scalars['Boolean']['output'];
  deleteSubscriptionPlan: Scalars['Boolean']['output'];
  deleteTlsCertificate: Scalars['Boolean']['output'];
  duplicateComponent: DuplicateComponentResult;
  duplicateCourse: DuplicateCourseResult;
  duplicateForm: Form;
  duplicateFormTemplate: FormTemplate;
  duplicatePage: Page;
  /**
   * Duplicate a page template. Note that specialPurpose is not copied because a platform cannot have more than one
   * template with the same special purpose.
   */
  duplicatePageTemplate: PageTemplate;
  duplicateSequence: DuplicateSequenceResult;
  duplicateStageBlueprint: StageBlueprint;
  editComponent: EditComponentResult;
  editCourse: EditCourseResult;
  /** Edit the custom table (i.e., custom fields) of a specific target object. */
  editCustomTable: EditCustomTableResult;
  editDocument: Document;
  editDocumentCurrentVersion: Document;
  editDocumentDownloadPrompt: DocumentDownloadPrompt;
  editFile: File;
  editForm: Form;
  editFormStep: FormStep;
  editFormStepPosition: FormStep;
  editFormSubmissionAction: FormSubmissionAction;
  editFormTemplate: FormTemplate;
  editFormTemplateConfirmation: FormTemplate;
  editFormTemplateEmailTemplate: EditFormTemplateEmailTemplateResult;
  editFormTemplateFormattedDataTemplate: EditFormTemplateFormattedDataTemplateResult;
  editFormTemplateStep: FormTemplateStep;
  editFormTemplateStepPosition: FormTemplateStep;
  editHelpTicketStatus: HelpTicket;
  editLayout: EditLayoutResult;
  editOrgPrimaryUser: EditOrgPrimaryUserResult;
  editOrganization: Organization;
  editPage: Page;
  editPageMetadata: Page;
  editPageProps: Page;
  editPagePurpose: EditPagePurposeResult;
  editPageStatus: Page;
  editPageTemplate: EditPageTemplateResult;
  editPlatform: EditPlatformResult;
  editPlatformOptions: Platform;
  editProduct: EditProductResult;
  editScoreMyCallEntryStatus?: Maybe<ScoreMyCallEntry>;
  editSequence: EditSequenceResult;
  editSiteDomain: Site;
  editSiteOptions: Site;
  editStageBlueprintCompletionSpec: StageBlueprint;
  editStageBlueprintComponents: StageBlueprint;
  editStageBlueprintMetaData: StageBlueprint;
  editStageState: Scalars['Boolean']['output'];
  editSubscription: Sub;
  editSubscriptionOrganization: SubscriptionOrganization;
  editUser: EditUserResult;
  editUserEmail: User;
  editUserName: User;
  editUserPlan: UserPlan;
  editUserPlatformPermissions: User;
  editUserRole: User;
  editUserStatus: User;
  findOrCreateStripeCustomer: StripeCustomer;
  findOrCreateStripeSubscription: FindOrCreateStripeSubscriptionResult;
  invalidateApiToken: InvalidateApiTokenResult;
  moveUserToOrg: MoveUserToOrgResult;
  refreshSession: Scalars['Boolean']['output'];
  requestPasswordReset: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  restorePageOptionVersion: PageOptionVersion;
  sendVerificationEmail: Scalars['Boolean']['output'];
  signIn: User;
  signOut: Scalars['Boolean']['output'];
  splitUserToNewOrg: SplitUserToNewOrgResult;
  submitForm: SubmitFormResult;
  submitReview: Scalars['Boolean']['output'];
  submitScoreMyCallEntry: SubmitScoreMyCallEntryResult;
  syncFormTemplateToForms: Scalars['Boolean']['output'];
  syncPageTemplateToPages: SyncPageTemplateToPagesResult;
  uploadFile: FileUploadResult;
  verifyEmailAddress: User;
};

export type MutationActivateSpecialPageArgs = {
  siteId: Scalars['String']['input'];
  specialPurpose: PageSpecialPurpose;
};

export type MutationArchiveComponentArgs = {
  id: Scalars['String']['input'];
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type MutationConsentToAgreementArgs = {
  agreementId: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type MutationCreateApiTokenArgs = {
  input: CreateApiTokenInput;
};

export type MutationCreateComponentArgs = {
  input: CreateComponentInput;
};

export type MutationCreateCourseArgs = {
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type MutationCreateDocumentArgs = {
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type MutationCreateDocumentDownloadPromptArgs = {
  acceptLabel: Scalars['String']['input'];
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
  prompt: DeltaInput;
  promptHeader: Scalars['String']['input'];
};

export type MutationCreateFeatureTagArgs = {
  platformId: Scalars['String']['input'];
  tag: Scalars['String']['input'];
};

export type MutationCreateFormArgs = {
  siteId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreateFormStepArgs = {
  formId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationCreateFormSubmissionActionArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  formId: Scalars['String']['input'];
  type: FormSubmissionActionType;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateFormTemplateArgs = {
  platformId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreateFormTemplateStepArgs = {
  formTemplateId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationCreateHelpTicketArgs = {
  customDomain?: InputMaybe<Scalars['String']['input']>;
  customSiteBodyEndCode?: InputMaybe<Scalars['String']['input']>;
  customSiteBodyStartCode?: InputMaybe<Scalars['String']['input']>;
  customSiteHeaderCode?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type MutationCreateLayoutArgs = {
  input: CreateLayoutInput;
};

export type MutationCreatePageArgs = {
  pagePurposeId?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreatePageFromTemplateArgs = {
  props?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};

export type MutationCreatePagePurposeArgs = {
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type MutationCreatePageTemplateArgs = {
  pagePurposeId?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationCreatePagesFromTemplatePurposeArgs = {
  latestTemplateOnly?: InputMaybe<Scalars['Boolean']['input']>;
  pagePurposeName: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type MutationCreateProductArgs = {
  alternativeStripePriceIds: Array<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  durationDays?: InputMaybe<Scalars['Int']['input']>;
  endAt?: InputMaybe<Scalars['Date']['input']>;
  invoiceFrequency?: InputMaybe<InvoiceFrequency>;
  items: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
  price: Scalars['String']['input'];
  promoCodes: Array<PromoCodeInput>;
  stripePriceId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateSequenceArgs = {
  loopStages: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type MutationCreateSiteArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  platformId: Scalars['String']['input'];
};

export type MutationCreateStageArgs = {
  date: Scalars['String']['input'];
  stageBlueprintId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
  userTimeZone: Scalars['String']['input'];
};

export type MutationCreateStageBlueprintArgs = {
  name: Scalars['String']['input'];
  planId: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

export type MutationCreateSubscriptionArgs = {
  notes: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  startAt: Scalars['Date']['input'];
  status: SubscriptionStatus;
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlanId: Scalars['String']['input'];
};

export type MutationCreateSubscriptionOrganizationArgs = {
  isSubscriptionManager: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type MutationCreateTlsCertificateArgs = {
  siteId: Scalars['String']['input'];
};

export type MutationCreateTlsCertificateMapEntryArgs = {
  certificateName: Scalars['String']['input'];
  domain: Scalars['String']['input'];
};

export type MutationCreateUserArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  platformId: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  status?: InputMaybe<UserStatus>;
};

export type MutationDeleteFeatureTagArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFormStepArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFormSubmissionActionArgs = {
  submissionActionId: Scalars['String']['input'];
};

export type MutationDeleteFormTemplateStepArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteStageBlueprintArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteSubscriptionOrganizationArgs = {
  id: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type MutationDeleteSubscriptionPlanArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteTlsCertificateArgs = {
  certificateName: Scalars['String']['input'];
};

export type MutationDuplicateComponentArgs = {
  input: DuplicateComponentInput;
};

export type MutationDuplicateCourseArgs = {
  id: Scalars['String']['input'];
};

export type MutationDuplicateFormArgs = {
  id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationDuplicateFormTemplateArgs = {
  templateId: Scalars['String']['input'];
};

export type MutationDuplicatePageArgs = {
  pageId: Scalars['String']['input'];
};

export type MutationDuplicatePageTemplateArgs = {
  id: Scalars['String']['input'];
};

export type MutationDuplicateSequenceArgs = {
  id: Scalars['String']['input'];
};

export type MutationDuplicateStageBlueprintArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

export type MutationEditComponentArgs = {
  input: EditComponentInput;
};

export type MutationEditCourseArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationEditCustomTableArgs = {
  input: EditCustomTableInput;
};

export type MutationEditDocumentArgs = {
  downloadPromptId?: InputMaybe<Scalars['String']['input']>;
  featureTag?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productItem?: InputMaybe<OptionalStringUpdate>;
  roles: Array<Role>;
  stamp: Scalars['Boolean']['input'];
  status: PublishStatus;
};

export type MutationEditDocumentCurrentVersionArgs = {
  fileId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditDocumentDownloadPromptArgs = {
  acceptLabel: Scalars['String']['input'];
  default: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  prompt: DeltaInput;
  promptHeader: Scalars['String']['input'];
};

export type MutationEditFileArgs = {
  downloadName: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditFormArgs = {
  components: Scalars['String']['input'];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: InputMaybe<Scalars['String']['input']>;
  confirmationRedirectUrl?: InputMaybe<Scalars['String']['input']>;
  confirmationRedirectUrlParameters: Array<FormConfirmationRedirectUrlParameterInput>;
  id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationEditFormStepArgs = {
  components: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationEditFormStepPositionArgs = {
  id: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationEditFormSubmissionActionArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  submissionActionId: Scalars['String']['input'];
  type: FormSubmissionActionType;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditFormTemplateArgs = {
  components: Scalars['String']['input'];
  id: Scalars['String']['input'];
  status: PublishStatus;
  title: Scalars['String']['input'];
};

export type MutationEditFormTemplateConfirmationArgs = {
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: InputMaybe<Scalars['String']['input']>;
  confirmationRedirectUrl?: InputMaybe<Scalars['String']['input']>;
  confirmationRedirectUrlParameters: Array<FormConfirmationRedirectUrlParameterInput>;
  id: Scalars['String']['input'];
};

export type MutationEditFormTemplateEmailTemplateArgs = {
  input: EditFormTemplateEmailTemplateInput;
};

export type MutationEditFormTemplateFormattedDataTemplateArgs = {
  input: EditFormTemplateFormattedDataTemplateInput;
};

export type MutationEditFormTemplateStepArgs = {
  components: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationEditFormTemplateStepPositionArgs = {
  id: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type MutationEditHelpTicketStatusArgs = {
  id: Scalars['String']['input'];
  status: HelpTicketStatus;
};

export type MutationEditLayoutArgs = {
  input: EditLayoutInput;
};

export type MutationEditOrgPrimaryUserArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationEditOrganizationArgs = {
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type MutationEditPageArgs = {
  bodyStyles: Scalars['String']['input'];
  caption?: InputMaybe<Scalars['String']['input']>;
  components: Scalars['String']['input'];
  featuredImageUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  layoutId?: InputMaybe<OptionalStringUpdate>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaRobots?: InputMaybe<Array<Scalars['String']['input']>>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  pagePurposeId?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  productItem?: InputMaybe<OptionalStringUpdate>;
  public: Scalars['Boolean']['input'];
  roles: Array<Role>;
  status: PublishStatus;
  title: Scalars['String']['input'];
};

export type MutationEditPageMetadataArgs = {
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  pageId: Scalars['String']['input'];
  path: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type MutationEditPagePropsArgs = {
  pageId: Scalars['String']['input'];
  props: Scalars['String']['input'];
};

export type MutationEditPagePurposeArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationEditPageStatusArgs = {
  pageId: Scalars['String']['input'];
  status: PublishStatus;
};

export type MutationEditPageTemplateArgs = {
  input: EditPageTemplateInput;
};

export type MutationEditPlatformArgs = {
  input: EditPlatformInput;
};

export type MutationEditPlatformOptionsArgs = {
  options: Array<OptionInput>;
  platformId: Scalars['String']['input'];
};

export type MutationEditProductArgs = {
  alternativeStripePriceIds: Array<Scalars['String']['input']>;
  description?: InputMaybe<OptionalStringUpdate>;
  displayName?: InputMaybe<OptionalStringUpdate>;
  durationDays?: InputMaybe<Scalars['Int']['input']>;
  endAt?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  invoiceFrequency?: InputMaybe<InvoiceFrequency>;
  items?: InputMaybe<StringArrayUpdate>;
  name: Scalars['String']['input'];
  price: Scalars['String']['input'];
  promoCodes: Array<PromoCodeInput>;
  stripePriceId?: InputMaybe<OptionalStringUpdate>;
};

export type MutationEditScoreMyCallEntryStatusArgs = {
  id: Scalars['String']['input'];
  status: ScoreMyCallEntryStatus;
};

export type MutationEditSequenceArgs = {
  id: Scalars['String']['input'];
  loopStages: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type MutationEditSiteDomainArgs = {
  domain: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditSiteOptionsArgs = {
  id: Scalars['String']['input'];
  options: Array<OptionInput>;
};

export type MutationEditStageBlueprintCompletionSpecArgs = {
  completionSpec: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditStageBlueprintComponentsArgs = {
  components: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditStageBlueprintMetaDataArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  restoreDeleted: Scalars['Boolean']['input'];
};

export type MutationEditStageStateArgs = {
  id: Scalars['String']['input'];
  property: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MutationEditSubscriptionArgs = {
  endAt?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  invoiceFrequency?: InputMaybe<InvoiceFrequency>;
  notes: Scalars['String']['input'];
  price: Scalars['String']['input'];
  startAt: Scalars['Date']['input'];
  status: SubscriptionStatus;
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationEditSubscriptionOrganizationArgs = {
  id: Scalars['String']['input'];
  isSubscriptionManager: Scalars['Boolean']['input'];
};

export type MutationEditUserArgs = {
  input: EditUserInput;
};

export type MutationEditUserEmailArgs = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationEditUserNameArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type MutationEditUserPlanArgs = {
  planId: Scalars['String']['input'];
  stageBlueprintId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationEditUserPlatformPermissionsArgs = {
  id: Scalars['String']['input'];
  permissions: Array<Permission>;
  platformId: Scalars['String']['input'];
};

export type MutationEditUserRoleArgs = {
  role: Role;
  userId: Scalars['String']['input'];
};

export type MutationEditUserStatusArgs = {
  status: UserStatus;
  userId: Scalars['String']['input'];
};

export type MutationFindOrCreateStripeCustomerArgs = {
  address?: InputMaybe<AddressInput>;
};

export type MutationFindOrCreateStripeSubscriptionArgs = {
  address: AddressInput;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
  promoCodeType?: InputMaybe<PromoCodeType>;
  subscriptionPlanId: Scalars['String']['input'];
};

export type MutationInvalidateApiTokenArgs = {
  id: Scalars['String']['input'];
};

export type MutationMoveUserToOrgArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  key: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationRestorePageOptionVersionArgs = {
  id: Scalars['String']['input'];
};

export type MutationSignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationSplitUserToNewOrgArgs = {
  notes?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type MutationSubmitFormArgs = {
  formData: Scalars['String']['input'];
  formId: Scalars['String']['input'];
  sessionId?: InputMaybe<Scalars['String']['input']>;
  stepId?: InputMaybe<Scalars['String']['input']>;
  submissionPageUrl: Scalars['String']['input'];
};

export type MutationSubmitReviewArgs = {
  review: Scalars['String']['input'];
  starRating: Scalars['Int']['input'];
  submitterName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSubmitScoreMyCallEntryArgs = {
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  formData: Scalars['String']['input'];
  prospectName: Scalars['String']['input'];
  shareRecording: Scalars['Boolean']['input'];
};

export type MutationSyncFormTemplateToFormsArgs = {
  formTemplateId: Scalars['String']['input'];
};

export type MutationSyncPageTemplateToPagesArgs = {
  pageTemplateId: Scalars['String']['input'];
};

export type MutationUploadFileArgs = {
  documentId?: InputMaybe<Scalars['String']['input']>;
  feature: FileUploadFeature;
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['String']['input']>;
  stageId?: InputMaybe<Scalars['String']['input']>;
  stageStateProperty?: InputMaybe<Scalars['String']['input']>;
};

export type MutationVerifyEmailAddressArgs = {
  key: Scalars['String']['input'];
};

/** Deprecated */
export type MutationResult = {
  /** Whether the mutation was successful. */
  success: Scalars['Boolean']['output'];
  /** A user-friendly error message, if the mutation was not successful. */
  userErrors?: Maybe<Array<UserError>>;
};

export type NavLinkItem = {
  __typename?: 'NavLinkItem';
  external: Scalars['Boolean']['output'];
  featureTag?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  roles?: Maybe<Array<Role>>;
  url: Scalars['String']['output'];
};

export type OptionInput = {
  key: Scalars['String']['input'];
  /** Any JSON value. */
  value: Scalars['String']['input'];
};

/**
 * An input type for optionally updating an array of components. Although the interface is the same as that
 * of OptionalStringUpdate, semantically this is different and is parsed differently.
 */
export type OptionalComponentsUpdate = {
  /** If true, value is ignored. */
  clear?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If clear is false, value is used as the new value, and a null value is taken as an empty array.
   * This must be a valid JSON array if set.
   */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type OptionalDateUpdate = {
  /** If true, value is ignored. */
  clear?: InputMaybe<Scalars['Boolean']['input']>;
  /** If clear is false, value is used as the new value, and a null value is equivalent to clearing the field. */
  value?: InputMaybe<Scalars['Date']['input']>;
};

/**
 * An input type for optionally updating an expression data object. Although the interface is the same as that
 * of OptionalStringUpdate, semantically this is different and is parsed differently.
 */
export type OptionalExpressionDataUpdate = {
  /** If true, value is ignored. */
  clear?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If clear is false, value is used as the new value, and a null value is taken as an empty object.
   * This must be a valid JSON object if set.
   */
  value?: InputMaybe<Scalars['String']['input']>;
};

/**
 * A filter for an optional string field. Either exists or filter must be provided but not both.
 * A value exists if it is not null. An empty string is considered to exist.
 * Providing a filter assumes exists is true.
 */
export type OptionalStringFilter = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<StringFilter>;
};

/**
 * A container for an input field representing an optional (i.e., nullable) string that may optionally
 * be updated. If an object of this type is provided, it represents either a new value or clears the field.
 */
export type OptionalStringUpdate = {
  /** If true, value is ignored. */
  clear?: InputMaybe<Scalars['Boolean']['input']>;
  /** If clear is false, value is used as the new value, and a null value is taken as an empty string. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String']['output'];
  members: Array<User>;
  name?: Maybe<Scalars['String']['output']>;
  platforms: Array<Platform>;
  primaryUser?: Maybe<User>;
  site?: Maybe<Site>;
  subscriptionOrganizations: Array<SubscriptionOrganization>;
};

export type OrganizationMembersArgs = {
  status?: InputMaybe<Array<UserStatus>>;
};

export const OrganizationPermission = {
  /** Edit anything only within one's own org. */
  Edit: 'Edit',
} as const;

export type OrganizationPermission =
  (typeof OrganizationPermission)[keyof typeof OrganizationPermission];
/**
 * A special subtype of Organization is used for the Sub's manager field to not leak extra details to the client about the
 * managing organization.
 */
export type OrganizationProfile = {
  __typename?: 'OrganizationProfile';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  primaryUser?: Maybe<UserProfile>;
};

export type OrganizationsList = ListResult & {
  __typename?: 'OrganizationsList';
  limit: Scalars['Int']['output'];
  organizations: Array<Organization>;
  totalCount: Scalars['Int']['output'];
};

export type Page = {
  __typename?: 'Page';
  bodyStyles: Scalars['String']['output'];
  caption?: Maybe<Scalars['String']['output']>;
  components: Scalars['String']['output'];
  edited: Scalars['Boolean']['output'];
  featuredImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  layout?: Maybe<Layout>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaRobots?: Maybe<Array<Scalars['String']['output']>>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  productItem?: Maybe<Scalars['String']['output']>;
  props?: Maybe<Scalars['String']['output']>;
  public: Scalars['Boolean']['output'];
  purpose?: Maybe<PagePurpose>;
  roles: Array<Role>;
  /** The special purpose of the page on its site. */
  specialPurpose: PageSpecialPurpose;
  status: PublishStatus;
  template?: Maybe<PageTemplate>;
  templateId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type PageOptionVersion = {
  __typename?: 'PageOptionVersion';
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PagePurpose = {
  __typename?: 'PagePurpose';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PagePurposesList = ListResult & {
  __typename?: 'PagePurposesList';
  limit: Scalars['Int']['output'];
  pagePurposes: Array<PagePurpose>;
  totalCount: Scalars['Int']['output'];
};

/** TODO: Order, VerifyEmailAddress, FormEntries, etc. */
export const PageSpecialPurpose = {
  GoogleReview: 'GoogleReview',
  None: 'None',
  PrivacyPolicy: 'PrivacyPolicy',
  TermsOfUse: 'TermsOfUse',
} as const;

export type PageSpecialPurpose = (typeof PageSpecialPurpose)[keyof typeof PageSpecialPurpose];
export type PageTemplate = {
  __typename?: 'PageTemplate';
  activatedAsPages: Array<Page>;
  bodyStyles: Scalars['String']['output'];
  components: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metaTitle?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  previewImageUrl?: Maybe<Scalars['String']['output']>;
  previewProps?: Maybe<Scalars['String']['output']>;
  propsSetupComponents?: Maybe<Scalars['String']['output']>;
  purpose?: Maybe<PagePurpose>;
  /** The special purpose of the page template when it is activated on a site. */
  specialPurpose: PageSpecialPurpose;
  status: PublishStatus;
  title: Scalars['String']['output'];
};

export type PageTemplateActivatedAsPagesArgs = {
  siteId: Scalars['String']['input'];
};

export type PageVersion = {
  __typename?: 'PageVersion';
  byUser: UserProfile;
  createdAt: Scalars['Date']['output'];
  /** A synthetic ID that is unique to this page version. */
  id: Scalars['String']['output'];
  optionVersions: Array<PageOptionVersion>;
};

export type PageVersionsList = ListResult & {
  __typename?: 'PageVersionsList';
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  versions: Array<PageVersion>;
};

export type PagesList = ListResult & {
  __typename?: 'PagesList';
  limit: Scalars['Int']['output'];
  pages: Array<Page>;
  totalCount: Scalars['Int']['output'];
};

export type PasswordResetInfo = {
  __typename?: 'PasswordResetInfo';
  name: Scalars['String']['output'];
};

export const PasswordStatus = {
  MigratedWP: 'MigratedWP',
  None: 'None',
  Ok: 'Ok',
  Temporary: 'Temporary',
} as const;

export type PasswordStatus = (typeof PasswordStatus)[keyof typeof PasswordStatus];
export const Permission = {
  /** Create API tokens. */
  CreateApiToken: 'CreateApiToken',
  /** Create page and form templates. */
  CreateTemplate: 'CreateTemplate',
  /** Create a user in any org. */
  CreateUser: 'CreateUser',
  /** Edit or manage everything else not covered by other permissions. */
  Edit: 'Edit',
  /** Edit, including creating or deleting, any custom component. */
  EditCustomComponent: 'EditCustomComponent',
  /** Edit, including creating or deleting, any custom field. */
  EditCustomField: 'EditCustomField',
  /** Edit any customer site, including its pages and forms, but not necessarily domain. */
  EditCustomerSite: 'EditCustomerSite',
  /** Edit any customer site's domain. */
  EditCustomerSiteDomain: 'EditCustomerSiteDomain',
  /** Edit any org's Google Place. */
  EditGooglePlace: 'EditGooglePlace',
  /** Edit any help ticket's status. */
  EditHelpTicketStatus: 'EditHelpTicketStatus',
  /** Edit any platform site, including its pages and forms, but not necessarily domain. */
  EditPlatformSite: 'EditPlatformSite',
  /** Edit any platform site's domain. */
  EditPlatformSiteDomain: 'EditPlatformSiteDomain',
  /** Edit any site's pages, forms, and settings. */
  EditSite: 'EditSite',
  /** Edit any site's TLS certificate, including creating and deleting certificates. */
  EditSiteTlsCertificate: 'EditSiteTlsCertificate',
  /** Edit any org's subscription level and subscription status. */
  EditSubscription: 'EditSubscription',
  /** Edit any page and form template. */
  EditTemplate: 'EditTemplate',
  /** Edit any user's email address. */
  EditUserEmail: 'EditUserEmail',
  /** Edit which org any user is in and whether a user is an org owner. */
  EditUserOrg: 'EditUserOrg',
  /** Edit any user's planStageBlueprintId. */
  EditUserPlanStageBlueprintId: 'EditUserPlanStageBlueprintId',
  /** Edit any user's first and last name. */
  EditUserProfile: 'EditUserProfile',
  /** Edit any user's role. */
  EditUserRole: 'EditUserRole',
  /** Edit any user's status. */
  EditUserStatus: 'EditUserStatus',
  /** Sync any form template to forms. */
  SyncFormTemplateToForms: 'SyncFormTemplateToForms',
  /** Sync any page template to pages. */
  SyncPageTemplateToPages: 'SyncPageTemplateToPages',
  /** View anything except for sites. */
  View: 'View',
  /** View and use any custom component. */
  ViewCustomComponent: 'ViewCustomComponent',
  /** View any customer site, including its pages and forms. */
  ViewCustomerSite: 'ViewCustomerSite',
  /** View any extension. */
  ViewExtension: 'ViewExtension',
  /** View any platform site, including its pages and forms. */
  ViewPlatformSite: 'ViewPlatformSite',
  /** View any page or form template. */
  ViewTemplate: 'ViewTemplate',
} as const;

export type Permission = (typeof Permission)[keyof typeof Permission];
export type Plan = {
  __typename?: 'Plan';
  emptyLessonNotesPrompt?: Maybe<Scalars['String']['output']>;
  enableLessonNotes: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** All of the Plan's StageBlueprints, which for authenticated users includes deleted StageBlueprints by default. */
  stageBlueprints: Array<StageBlueprint>;
  takeaways: Array<UserTakeaways>;
};

export type PlanStageBlueprintsArgs = {
  status?: InputMaybe<StageBlueprintStatus>;
};

export type PlanTakeawaysArgs = {
  userId: Scalars['String']['input'];
};

export type Platform = {
  __typename?: 'Platform';
  activeCampaignApi?: Maybe<ActiveCampaignApi>;
  activeCampaignApiToken?: Maybe<Scalars['String']['output']>;
  activeCampaignApiUrl?: Maybe<Scalars['String']['output']>;
  activeCampaignTagsSyncConfig?: Maybe<Array<ActiveCampaignTagsSyncConfig>>;
  defaultFormFormattedDataTemplate: Scalars['String']['output'];
  defaultFormNotificationEmailBodyTemplate: Scalars['String']['output'];
  emailReplyToAddress?: Maybe<Scalars['String']['output']>;
  emailSendFromAddress: Scalars['String']['output'];
  enableComponentClasses: Scalars['Boolean']['output'];
  enablePagePurposes: Scalars['Boolean']['output'];
  enableScoreMyCall: Scalars['Boolean']['output'];
  handle: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mainSiteId?: Maybe<Scalars['String']['output']>;
  mixpanelToken?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sendGridApiKey?: Maybe<Scalars['String']['output']>;
  sitesRootDomain?: Maybe<Scalars['String']['output']>;
  stripePublishableKey?: Maybe<Scalars['String']['output']>;
  stripeSecretKey?: Maybe<Scalars['String']['output']>;
  stripeWebhookSigningSecret?: Maybe<Scalars['String']['output']>;
  webhookHelpTicketCreatedUrl?: Maybe<Scalars['String']['output']>;
  webhookOrganizationPeerUserCreatedUrl?: Maybe<Scalars['String']['output']>;
  webhookRegisterUserSecret?: Maybe<Scalars['String']['output']>;
  webhookSubscriptionActivatedUrl?: Maybe<Scalars['String']['output']>;
  zendeskApiToken?: Maybe<Scalars['String']['output']>;
  zendeskHelpTicketTags?: Maybe<Array<Scalars['String']['output']>>;
  zendeskSubdomain?: Maybe<Scalars['String']['output']>;
  zendeskUserAuthJwtKey?: Maybe<Scalars['String']['output']>;
  zendeskUserAuthJwtKeyId?: Maybe<Scalars['String']['output']>;
  zendeskUsername?: Maybe<Scalars['String']['output']>;
  zendeskWebhookSigningSecret?: Maybe<Scalars['String']['output']>;
};

export type PreviewStripeCoupon = {
  __typename?: 'PreviewStripeCoupon';
  amountOff?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  duration: StripeCouponDuration;
  /** The number of months. Only applies of duration is repeating. */
  durationMonths?: Maybe<Scalars['Int']['output']>;
  /** The percentage off the price of the plan (0-100). */
  percentOff?: Maybe<Scalars['Int']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type PreviewStripePromoCode = {
  __typename?: 'PreviewStripePromoCode';
  coupon: PreviewStripeCoupon;
  valid: Scalars['Boolean']['output'];
};

export type PreviewStripeUpcomingInvoiceErrorResult = {
  __typename?: 'PreviewStripeUpcomingInvoiceErrorResult';
  message: Scalars['String']['output'];
};

export type PreviewStripeUpcomingInvoiceResult =
  | PreviewStripeUpcomingInvoiceErrorResult
  | PreviewStripeUpcomingInvoiceSuccessResult;

export type PreviewStripeUpcomingInvoiceSuccessResult = {
  __typename?: 'PreviewStripeUpcomingInvoiceSuccessResult';
  currency: Scalars['String']['output'];
  promoCode?: Maybe<PreviewStripePromoCode>;
  subscriptionPlanId: Scalars['String']['output'];
  /** Total of the invoice before any invoice-level discount or exclusive tax is applied. Formatted in the invoice's currency. */
  subtotal: Scalars['String']['output'];
  /** Total after discounts and taxes. Formatted in the invoice's currency. */
  total: Scalars['String']['output'];
};

export type ProductItemOccurrences = {
  __typename?: 'ProductItemOccurrences';
  documents: DocumentsList;
  pages: PagesList;
};

export type ProductItemOccurrencesDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductItemOccurrencesPagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductItemPicklist = ListResult & {
  __typename?: 'ProductItemPicklist';
  limit: Scalars['Int']['output'];
  productItems: Array<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type ProductsList = ListResult & {
  __typename?: 'ProductsList';
  limit: Scalars['Int']['output'];
  products: Array<SubscriptionPlan>;
  totalCount: Scalars['Int']['output'];
};

export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  successMessage?: Maybe<Delta>;
};

export type PromoCodeInput = {
  code: Scalars['String']['input'];
  id: Scalars['String']['input'];
  successMessage?: InputMaybe<DeltaInput>;
};

export type PromoCodePicklist = ListResult & {
  __typename?: 'PromoCodePicklist';
  limit: Scalars['Int']['output'];
  promoCodes: Array<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export const PromoCodeType = {
  Custom: 'Custom',
  Stripe: 'Stripe',
} as const;

export type PromoCodeType = (typeof PromoCodeType)[keyof typeof PromoCodeType];
export const PublishStatus = {
  Deleted: 'Deleted',
  Draft: 'Draft',
  Published: 'Published',
} as const;

export type PublishStatus = (typeof PublishStatus)[keyof typeof PublishStatus];
export type Query = {
  __typename?: 'Query';
  apiTokens: ApiTokensList;
  applyCustomPromoCode: ApplyCustomPromoCodeResult;
  component: Component;
  components: ComponentsList;
  contentPlans: ContentPlansList;
  course: Course;
  courses: CoursesList;
  currentSite: Site;
  customTables: CustomTablesList;
  districtDashboard: DistrictDashboard;
  document: Document;
  documentDownloadPrompts: Array<DocumentDownloadPrompt>;
  documents: Array<Document>;
  extensions: ExtensionsList;
  featureTags: Array<FeatureTag>;
  file: File;
  form: Form;
  formEntries: FormEntriesList;
  formTemplate: FormTemplate;
  formTemplates: Array<FormTemplate>;
  forms: Array<Form>;
  helpTickets: Array<HelpTicket>;
  /**
   * Returns a JSON object with the form data with which to initialize the specified form.
   * The API factors in the signed in user, if any.
   * The request needs to be sent to the API of the site on which the form is being shown.
   */
  initialFormData: InitialFormData;
  latestAgreement?: Maybe<Agreement>;
  layout: Layout;
  layouts: LayoutsList;
  liveCustomFields: Array<CustomField>;
  me?: Maybe<User>;
  multiOrgView: Array<Organization>;
  organization: Organization;
  /** List organizations. A {Limit} is always applied on front end. */
  organizations: OrganizationsList;
  page: Page;
  pagePurposes: PagePurposesList;
  /** Get a page template. For unauthenticated requests, a template is returned only if it's published. */
  pageTemplate: PageTemplate;
  pageTemplates: Array<PageTemplate>;
  pageVersions: PageVersionsList;
  passwordResetInfo: PasswordResetInfo;
  plan: Plan;
  platform: Platform;
  previewStripeUpcomingInvoice: PreviewStripeUpcomingInvoiceResult;
  productItemOccurrences: ProductItemOccurrences;
  productItemPicklist: ProductItemPicklist;
  products: ProductsList;
  promoCodePicklist: PromoCodePicklist;
  reviews: ReviewsList;
  scoreMyCallEntries: Array<ScoreMyCallEntry>;
  scoreMyCallEntryFileUrl: Scalars['String']['output'];
  sequence: Sequence;
  sequences: SequencesList;
  site: Site;
  /** List sites. A limit of 10 is always applied. */
  sites: SitesList;
  stageBlueprint: StageBlueprint;
  stages: Array<Stage>;
  subscription: Sub;
  subscriptionAuditLogs: SubscriptionAuditLogsList;
  /**
   * Get the subscription checkout options for the specified products. The options are sorted in the same order
   * as the input IDs.
   */
  subscriptionCheckoutOptions: Array<SubscriptionCheckoutOption>;
  subscriptionPlan: SubscriptionPlan;
  subscriptions: SubscriptionsList;
  user: User;
  userAuditLogs: UserAuditLogsList;
  /** List users. A limit of 10 is always applied. */
  users: UsersList;
};

export type QueryApplyCustomPromoCodeArgs = {
  promoCode: Scalars['String']['input'];
  subscriptionPlanId: Scalars['String']['input'];
};

export type QueryComponentArgs = {
  id: Scalars['String']['input'];
};

export type QueryComponentsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  platformId: Scalars['String']['input'];
};

export type QueryContentPlansArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryCourseArgs = {
  id: Scalars['String']['input'];
};

export type QueryCoursesArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryCustomTablesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  object?: InputMaybe<CustomTableObject>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
  target?: InputMaybe<CustomTableTarget>;
};

export type QueryDistrictDashboardArgs = {
  from: Scalars['Date']['input'];
  organizationId: Scalars['String']['input'];
  to: Scalars['Date']['input'];
};

export type QueryDocumentArgs = {
  id: Scalars['String']['input'];
};

export type QueryDocumentDownloadPromptsArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryDocumentsArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryExtensionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
};

export type QueryFeatureTagsArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryFileArgs = {
  id: Scalars['String']['input'];
};

export type QueryFormArgs = {
  id: Scalars['String']['input'];
};

export type QueryFormEntriesArgs = {
  formId?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryFormTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryFormTemplatesArgs = {
  platformId: Scalars['String']['input'];
  status?: InputMaybe<PublishStatus>;
};

export type QueryFormsArgs = {
  siteId: Scalars['String']['input'];
};

export type QueryHelpTicketsArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  platformId: Scalars['String']['input'];
};

export type QueryInitialFormDataArgs = {
  formId: Scalars['String']['input'];
  pageId: Scalars['String']['input'];
};

export type QueryLatestAgreementArgs = {
  agreementType: AgreementType;
};

export type QueryLayoutArgs = {
  id: Scalars['String']['input'];
};

export type QueryLayoutsArgs = {
  siteId: Scalars['String']['input'];
};

export type QueryLiveCustomFieldsArgs = {
  object: CustomTableObject;
  platformId: Scalars['String']['input'];
};

export type QueryMultiOrgViewArgs = {
  organizationId: Scalars['String']['input'];
};

export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};

export type QueryOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPageArgs = {
  id: Scalars['String']['input'];
};

export type QueryPagePurposesArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryPageTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryPageTemplatesArgs = {
  pagePurposeName?: InputMaybe<Scalars['String']['input']>;
  platformId: Scalars['String']['input'];
  specialPurpose?: InputMaybe<PageSpecialPurpose>;
  status?: InputMaybe<PublishStatus>;
};

export type QueryPageVersionsArgs = {
  byUserId?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageId: Scalars['String']['input'];
};

export type QueryPasswordResetInfoArgs = {
  key: Scalars['String']['input'];
};

export type QueryPlanArgs = {
  id: Scalars['String']['input'];
};

export type QueryPlatformArgs = {
  id: Scalars['String']['input'];
};

export type QueryPreviewStripeUpcomingInvoiceArgs = {
  promoCode?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlanId: Scalars['String']['input'];
};

export type QueryProductItemOccurrencesArgs = {
  item: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};

export type QueryProductItemPicklistArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platformId: Scalars['String']['input'];
};

export type QueryProductsArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryPromoCodePicklistArgs = {
  platformId: Scalars['String']['input'];
};

export type QueryReviewsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
};

export type QueryScoreMyCallEntriesArgs = {
  platformId: Scalars['String']['input'];
  shareRecording?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryScoreMyCallEntryFileUrlArgs = {
  download?: InputMaybe<Scalars['Boolean']['input']>;
  entryId: Scalars['String']['input'];
};

export type QuerySequenceArgs = {
  id: Scalars['String']['input'];
};

export type QuerySequencesArgs = {
  platformId: Scalars['String']['input'];
};

export type QuerySiteArgs = {
  id: Scalars['String']['input'];
};

export type QuerySitesArgs = {
  page: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  platformSites?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryStageBlueprintArgs = {
  id: Scalars['String']['input'];
};

export type QueryStagesArgs = {
  fromDate: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  toDate: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySubscriptionArgs = {
  id: Scalars['String']['input'];
};

export type QuerySubscriptionAuditLogsArgs = {
  actionByUserId?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySubscriptionCheckoutOptionsArgs = {
  subscriptionPlanIds: Array<Scalars['String']['input']>;
};

export type QuerySubscriptionPlanArgs = {
  id: Scalars['String']['input'];
};

export type QuerySubscriptionsArgs = {
  endAtFrom?: InputMaybe<Scalars['Date']['input']>;
  endAtTo?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['Int']['input'];
  planId?: InputMaybe<Scalars['String']['input']>;
  platformId: Scalars['String']['input'];
  promoCode?: InputMaybe<OptionalStringFilter>;
  status?: InputMaybe<SubscriptionStatus>;
};

export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

export type QueryUserAuditLogsArgs = {
  actionByUserId?: InputMaybe<Scalars['String']['input']>;
  affectedUserId?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
};

export type QueryUsersArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
};

export type Review = {
  __typename?: 'Review';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  review?: Maybe<Scalars['String']['output']>;
  starRating: Scalars['Int']['output'];
  submitterName?: Maybe<Scalars['String']['output']>;
};

export type ReviewsList = ListResult & {
  __typename?: 'ReviewsList';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  reviews: Array<Review>;
  totalCount: Scalars['Int']['output'];
};

export const Role = {
  OrganizationOwner: 'OrganizationOwner',
  Protege: 'Protege',
  ReserveAgent: 'ReserveAgent',
  TeamMember: 'TeamMember',
} as const;

export type Role = (typeof Role)[keyof typeof Role];
export type ScoreMyCallEntry = {
  __typename?: 'ScoreMyCallEntry';
  createdAt: Scalars['Date']['output'];
  formData: Scalars['String']['output'];
  id: Scalars['String']['output'];
  prospectName?: Maybe<Scalars['String']['output']>;
  shareRecording: Scalars['Boolean']['output'];
  status: ScoreMyCallEntryStatus;
  user: User;
  userId: Scalars['String']['output'];
};

export const ScoreMyCallEntryStatus = {
  NotReviewed: 'NotReviewed',
  Reviewed: 'Reviewed',
} as const;

export type ScoreMyCallEntryStatus =
  (typeof ScoreMyCallEntryStatus)[keyof typeof ScoreMyCallEntryStatus];
export type Sequence = {
  __typename?: 'Sequence';
  id: Scalars['String']['output'];
  loopStages: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** All of the Sequence's StageBlueprints, which for authenticated users includes deleted StageBlueprints by default. */
  stageBlueprints: Array<StageBlueprint>;
};

export type SequenceStageBlueprintsArgs = {
  status?: InputMaybe<StageBlueprintStatus>;
};

export type SequencesList = ListResult & {
  __typename?: 'SequencesList';
  limit: Scalars['Int']['output'];
  sequences: Array<Sequence>;
  totalCount: Scalars['Int']['output'];
};

export type Site = {
  __typename?: 'Site';
  addressCity?: Maybe<Scalars['String']['output']>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  addressZip?: Maybe<Scalars['String']['output']>;
  agencyPrimaryPhone?: Maybe<Scalars['String']['output']>;
  agentFirstName?: Maybe<Scalars['String']['output']>;
  agentLastName?: Maybe<Scalars['String']['output']>;
  agentPhotoUrl?: Maybe<Scalars['String']['output']>;
  agreementConsentsRequired: Array<AgreementType>;
  authenticatedMainNavItems?: Maybe<Array<NavLinkItem>>;
  bodyEndCode?: Maybe<Scalars['String']['output']>;
  colorPrimary: ColorHsl;
  colorPrimaryDark: ColorHsl;
  colorPrimaryLight: ColorHsl;
  domain: Scalars['String']['output'];
  fontFamily: Scalars['String']['output'];
  /** Get a form specifically for rendering it on a site. */
  form: SiteForm;
  globalCss?: Maybe<Scalars['String']['output']>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  googleTagManagerId?: Maybe<Scalars['String']['output']>;
  headEndCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use headEndCode instead. */
  headJavaScriptCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inputFieldFontFamily: Scalars['String']['output'];
  logoIconUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  metaPixelId?: Maybe<Scalars['String']['output']>;
  mixpanelToken?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  /** Get a published page by path. */
  page?: Maybe<Page>;
  /**
   * List pages. For requests by a user who does not have permission to view all the site's pages, the status argument
   * is ignored and only published pages are returned.
   */
  pages: Array<Page>;
  platformId: Scalars['String']['output'];
  privacyPolicy?: Maybe<Delta>;
  privacyPolicyUrl?: Maybe<Scalars['String']['output']>;
  radixThemeColorName?: Maybe<Scalars['String']['output']>;
  sidebarExtraItems?: Maybe<Array<NavLinkItem>>;
  stripePublishableKey?: Maybe<Scalars['String']['output']>;
  termsOfUse?: Maybe<Delta>;
  termsOfUseUrl?: Maybe<Scalars['String']['output']>;
  textColorPrimaryH: Scalars['String']['output'];
  textColorPrimaryL: Scalars['String']['output'];
  textColorPrimaryS: Scalars['String']['output'];
  tlsCertificates: Array<TlsCertificate>;
  useCustomPrivacyPolicy: Scalars['Boolean']['output'];
  useCustomTermsOfUse: Scalars['Boolean']['output'];
  zendeskChatKey?: Maybe<Scalars['String']['output']>;
};

export type SiteFormArgs = {
  id: Scalars['String']['input'];
};

export type SitePageArgs = {
  path: Scalars['String']['input'];
};

export type SitePagesArgs = {
  pagePurposeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  pathPrefix?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  specialPurposes?: InputMaybe<Array<PageSpecialPurpose>>;
  status?: InputMaybe<Array<PublishStatus>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SiteForm = {
  __typename?: 'SiteForm';
  components: Scalars['String']['output'];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents?: Maybe<Scalars['String']['output']>;
  confirmationRedirectParameters: Array<FormConfirmationRedirectUrlParameter>;
  confirmationRedirectUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Only the enabled steps. */
  steps: Array<FormStep>;
};

export type SitesList = ListResult & {
  __typename?: 'SitesList';
  limit: Scalars['Int']['output'];
  sites: Array<Site>;
  totalCount: Scalars['Int']['output'];
};

export type SplitUserToNewOrgResult = GenericUserError | SplitUserToNewOrgSuccessResult;

export type SplitUserToNewOrgSuccessResult = {
  __typename?: 'SplitUserToNewOrgSuccessResult';
  user: User;
};

export type Stage = {
  __typename?: 'Stage';
  carryOver: Scalars['Boolean']['output'];
  date: Scalars['String']['output'];
  files: Array<File>;
  id: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  stageBlueprint: StageBlueprint;
  stageBlueprintId: Scalars['String']['output'];
  state: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type StageBlueprint = {
  __typename?: 'StageBlueprint';
  completedAt: Array<Scalars['Date']['output']>;
  completionSpec: Scalars['String']['output'];
  components: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  /** Whether the latest stage of the specified user for this stage blueprint is completed. */
  latestStageCompleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  planId: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  subLessons: Array<CourseSubLesson>;
};

export type StageBlueprintCompletedAtArgs = {
  userId: Scalars['String']['input'];
};

export type StageBlueprintLatestStageCompletedArgs = {
  userId: Scalars['String']['input'];
};

export const StageBlueprintStatus = {
  Active: 'Active',
  Deleted: 'Deleted',
} as const;

export type StageBlueprintStatus = (typeof StageBlueprintStatus)[keyof typeof StageBlueprintStatus];
export type StringArrayUpdate = {
  value: Array<Scalars['String']['input']>;
};

export type StringFilter = {
  operator: StringFilterOperator;
  value: Scalars['String']['input'];
};

export const StringFilterOperator = {
  Contains: 'Contains',
  Equals: 'Equals',
  NotContains: 'NotContains',
  NotEquals: 'NotEquals',
} as const;

export type StringFilterOperator = (typeof StringFilterOperator)[keyof typeof StringFilterOperator];
/**
 * A container for an input field representing a required (i.e., non-null) string that may optionally
 * be updated. If an object of this type is provided, its value becomes the new value of the field.
 */
export type StringUpdate = {
  value: Scalars['String']['input'];
};

/** The supported coupon duration types. */
export const StripeCouponDuration = {
  Forever: 'Forever',
  Once: 'Once',
  Repeating: 'Repeating',
} as const;

export type StripeCouponDuration = (typeof StripeCouponDuration)[keyof typeof StripeCouponDuration];
export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  address?: Maybe<Address>;
  id: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type Sub = {
  __typename?: 'Sub';
  endAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  invoiceFrequency?: Maybe<InvoiceFrequency>;
  managerOrganization?: Maybe<OrganizationProfile>;
  notes: Array<SubscriptionNote>;
  plan: SubscriptionPlan;
  planId: Scalars['String']['output'];
  planProfile: SubscriptionPlanProfile;
  price: Scalars['String']['output'];
  promoCode?: Maybe<Scalars['String']['output']>;
  startAt: Scalars['Date']['output'];
  status: SubscriptionStatus;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  /**
   * The SubscriptionOrganizations connecting this subscription to the organizations that are included in it.
   * If the requesting user is not a member of the manager organization, the returned array will include only the user's
   * own organization.
   */
  subscriptionOrganizations: Array<SubscriptionOrganization>;
};

export type SubmitFormResult = {
  __typename?: 'SubmitFormResult';
  sessionId: Scalars['String']['output'];
};

export type SubmitScoreMyCallEntryResult = {
  __typename?: 'SubmitScoreMyCallEntryResult';
  signedUploadUrl: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  completedStagesChanged: CompletedStagesChangedResult;
  stageUpdated?: Maybe<Stage>;
};

export type SubscriptionCompletedStagesChangedArgs = {
  userId: Scalars['String']['input'];
};

export type SubscriptionStageUpdatedArgs = {
  id: Scalars['String']['input'];
};

export type SubscriptionAuditLog = {
  __typename?: 'SubscriptionAuditLog';
  action: Scalars['String']['output'];
  actionByUser: UserProfile;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  subscription: Sub;
};

export type SubscriptionAuditLogsList = {
  __typename?: 'SubscriptionAuditLogsList';
  logs: Array<SubscriptionAuditLog>;
  totalCount: Scalars['Int']['output'];
};

/** A subset of SubscriptionPlan fields. */
export type SubscriptionCheckoutOption = {
  __typename?: 'SubscriptionCheckoutOption';
  /** Either the displayName of the SubscriptionPlan, if set, or the name. */
  displayName: Scalars['String']['output'];
  /** The ID of a SubscriptionPlan. */
  id: Scalars['String']['output'];
  invoiceFrequency?: Maybe<InvoiceFrequency>;
  price: Scalars['String']['output'];
};

export type SubscriptionNote = {
  __typename?: 'SubscriptionNote';
  byUser: UserProfile;
  byUserId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  notes: Scalars['String']['output'];
};

export type SubscriptionOrganization = {
  __typename?: 'SubscriptionOrganization';
  id: Scalars['String']['output'];
  isSubscriptionManager: Scalars['Boolean']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  subscription: Sub;
  subscriptionId: Scalars['String']['output'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  alternativeStripePriceIds: Array<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  durationDays?: Maybe<Scalars['Int']['output']>;
  endAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  /** The invoice frequency for this plan. This will be null for plans that have a price of 0. */
  invoiceFrequency?: Maybe<InvoiceFrequency>;
  items: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['String']['output'];
  promoCodes: Array<PromoCode>;
  stripePriceId?: Maybe<Scalars['String']['output']>;
};

/** This type limits the type of data that can be queried from a SubscriptionPlan. */
export type SubscriptionPlanProfile = {
  __typename?: 'SubscriptionPlanProfile';
  displayName?: Maybe<Scalars['String']['output']>;
  /** The ID of a SubscriptionPlan. */
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export const SubscriptionStatus = {
  Active: 'Active',
  Canceled: 'Canceled',
  Incomplete: 'Incomplete',
  IncompleteExpired: 'IncompleteExpired',
  PastDue: 'PastDue',
  Paused: 'Paused',
  Replaced: 'Replaced',
  Trialing: 'Trialing',
  Unpaid: 'Unpaid',
} as const;

export type SubscriptionStatus = (typeof SubscriptionStatus)[keyof typeof SubscriptionStatus];
export type SubscriptionsList = ListResult & {
  __typename?: 'SubscriptionsList';
  limit: Scalars['Int']['output'];
  subscriptions: Array<Sub>;
  totalCount: Scalars['Int']['output'];
};

export type SyncPageTemplateToPagesResult = GenericUserError | SyncPageTemplateToPagesSuccessResult;

export type SyncPageTemplateToPagesSuccessResult = {
  __typename?: 'SyncPageTemplateToPagesSuccessResult';
  /** The number of non-deleted pages that were not synced because they have been edited. */
  notSyncedPagesCount: Scalars['Int']['output'];
  /** The number of non-deleted pages that were synced. */
  syncedPagesCount: Scalars['Int']['output'];
};

/**
 * An abstraction for a TLS certificate for a particular domain. The actual certificate may cover other domains and
 * may be used by multiple certificate map entries.
 */
export type TlsCertificate = {
  __typename?: 'TlsCertificate';
  domain: Scalars['String']['output'];
  /** A unique string derived from the certificate's name and this domain. */
  id: Scalars['String']['output'];
  /** The state of the associated certificate map entry. This will be null if no map entry is found. */
  mapEntryState?: Maybe<GCloudCertificateMapEntryState>;
  /** The full certificate name as returned by the underlying certificate provider. */
  name: Scalars['String']['output'];
  provisioningIssueDetails?: Maybe<Scalars['String']['output']>;
  state: GCloudCertificateState;
};

export type User = {
  __typename?: 'User';
  /** The latest agreement types on the current site (based on request host) to which the user has given consent. */
  currentAgreementsSigned: Array<AgreementType>;
  custom?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isEmailVerified: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  orgPrimary: Scalars['Boolean']['output'];
  organization: Organization;
  /** The user's permissions in their own organization. */
  organizationPermissions: Array<OrganizationPermission>;
  passwordExpiresAt?: Maybe<Scalars['Date']['output']>;
  passwordStatus: PasswordStatus;
  /**
   * Find or create this user's UserPlan for the specified Plan. Any user can access this field for themselves with the
   * ID of a Plan owned by the platform they belong to. A user can also access this field with the ID of a Plan owned by
   * a platform on which they have Permission.EditUserPlanStageBlueprintId.
   */
  plan: UserPlan;
  planProgress: Array<UserPlanProgress>;
  plans: Array<UserPlan>;
  platformPermissions: Array<UserPlatformPermissions>;
  /** All items from all of the active subscriptions of the user's organization. */
  productItems: Array<Scalars['String']['output']>;
  role: Role;
  status: UserStatus;
  zendeskChatToken?: Maybe<Scalars['String']['output']>;
};

export type UserPlanArgs = {
  planId: Scalars['String']['input'];
};

export type UserPlanProgressArgs = {
  contentPlanIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserAuditLog = {
  __typename?: 'UserAuditLog';
  action: Scalars['String']['output'];
  actionByUser: UserProfile;
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  user: UserProfile;
};

export type UserAuditLogsList = {
  __typename?: 'UserAuditLogsList';
  logs: Array<UserAuditLog>;
  totalCount: Scalars['Int']['output'];
};

export type UserError = {
  /** A user-friendly error message. */
  message: Scalars['String']['output'];
};

export type UserPlan = {
  __typename?: 'UserPlan';
  id: Scalars['String']['output'];
  nextStageBlueprintId?: Maybe<Scalars['String']['output']>;
  plan: Plan;
  planId: Scalars['String']['output'];
  previousStageBlueprintId?: Maybe<Scalars['String']['output']>;
  stageBlueprint: StageBlueprint;
  userId: Scalars['String']['output'];
};

export type UserPlanProgress = {
  __typename?: 'UserPlanProgress';
  completedStageBlueprintIds: Array<Scalars['String']['output']>;
  plan: Plan;
  planId: Scalars['String']['output'];
  startedStageBlueprintIds: Array<Scalars['String']['output']>;
  totalStageBlueprints: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

export type UserPlatformPermissions = {
  __typename?: 'UserPlatformPermissions';
  permissions: Array<Permission>;
  platform: Platform;
  platformId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** A UserProfile is a minimal set of User fields. */
export type UserProfile = {
  __typename?: 'UserProfile';
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export const UserStatus = {
  Active: 'Active',
  Deactivated: 'Deactivated',
  ExpiredInactive: 'ExpiredInactive',
  Pending: 'Pending',
} as const;

export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];
export type UserTakeaways = {
  __typename?: 'UserTakeaways';
  stageBlueprintId: Scalars['String']['output'];
  stageBlueprintName: Scalars['String']['output'];
  takeaways: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UsersList = ListResult & {
  __typename?: 'UsersList';
  limit: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  users: Array<User>;
};
