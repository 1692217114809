import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { MultiSelectInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { clsx } from 'clsx';
import { isNumber } from 'lodash-es';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';

enum SubmissionState {
  Correct,
  Incorrect,
}

const GuardedMultiSelectInput: FunctionComponent<ValidInputComponent<MultiSelectInputComponent>> = (
  props,
) => {
  const { getValue, setValue } = useInputDataContext(props);
  const inputIdRoot = useInputId(props);
  const inputValue = getValue(props) ?? null;
  const question = props.question;
  const choices = props.choices ?? [];
  const answerIndices = props.answerIndices ?? [];

  const [submissionState, setSubmissionState] = useState<SubmissionState | null>(null);

  const [selections, setSelections] = useState<number[]>([]);

  const checkSubmission = () => {
    if (
      answerIndices.every((answerIndex) => selections.includes(answerIndex)) &&
      answerIndices.length === selections.length
    ) {
      setValue(props, true);
      setSubmissionState(SubmissionState.Correct);
    } else {
      setSubmissionState(SubmissionState.Incorrect);
    }
  };

  return (
    <div className={componentClassName(props)}>
      <div className="flex gap-2">
        {question && <div className="font-medium">{question}</div>}
        {inputValue === true ? (
          <div className="text-green-500">
            <i className="pi pi-check" />
          </div>
        ) : submissionState === SubmissionState.Incorrect ? (
          <div className="text-red-500 flex align-items-center">
            <i className="pi pi-times" />
          </div>
        ) : null}
      </div>
      <div className="flex flex-column gap-2 mt-3 ml-2">
        {choices.map((choice, index) => (
          <div key={index} className="flex gap-2">
            <Checkbox
              inputId={`${inputIdRoot}-${index}`}
              className={clsx(inputValue && 'pointer-events-none')}
              key={index}
              name={props.id}
              value={index}
              checked={
                selections.includes(index) || (!!inputValue && answerIndices.includes(index))
              }
              onChange={({ value }) => {
                if (!inputValue && isNumber(value)) {
                  setSubmissionState(null);
                  setSelections(
                    selections.includes(value)
                      ? selections.filter((s) => s !== value)
                      : [...selections, value],
                  );
                }
              }}
            />
            <label
              htmlFor={`${inputIdRoot}-${index}`}
              className={clsx(
                'w-full',
                inputValue && answerIndices.includes(index) && 'pointer-events-none',
              )}
            >
              {choice}
            </label>
          </div>
        ))}
      </div>
      {!inputValue && (
        <div className="flex align-items-center gap-2 mt-3">
          <Button
            label="Submit"
            disabled={!!inputValue}
            className="w-fit p-button-sm"
            onClick={checkSubmission}
          />
          {submissionState === SubmissionState.Incorrect && <span>Not quite. Try again!</span>}
        </div>
      )}
    </div>
  );
};

export const MultiSelectInput =
  withValidInputComponent<MultiSelectInputComponent>(GuardedMultiSelectInput);
