import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { CalendlyEmbedComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { stringDefaultNull } from '@wirechunk/lib/strings.ts';
import { isNumber, isString, noop } from 'lodash-es';
import type { FunctionComponent } from 'react';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePropsContext } from '../../../contexts/props-context.ts';

type InitInlineWidgetOptions = {
  url: string;
  parentElement: HTMLElement;
  prefill?: Record<string, string>;
  utm?: {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmContent?: string;
    utmTerm?: string;
  };
};

declare global {
  interface Window {
    Calendly?: {
      initInlineWidget: (options: InitInlineWidgetOptions) => void;
    };
  }
}

// See https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview
const queryParamsToKeepInBaseUrl = ['hide_landing_page_details', 'hide_gdpr_banner'];

export const CalendlyEmbed: FunctionComponent<CalendlyEmbedComponent> = ({
  url: urlSource,
  queryParameters,
  ...props
}) => {
  const [searchParams] = useSearchParams();
  const propsContext = usePropsContext();
  const parent = useRef<HTMLDivElement>(null);
  const initialized = useRef(false);

  useEffect(() => {
    const { current: parentElement } = parent;
    if (!initialized.current && parentElement) {
      let urlBase: string | null = null;
      if (urlSource?.type === DataSource.Direct) {
        if (urlSource.url) {
          urlBase = urlSource.url;
        }
      } else if (urlSource?.name) {
        urlBase = stringDefaultNull(propsContext[urlSource.name]);
      }
      if (urlBase) {
        try {
          const parsedUrl = new URL(urlBase);

          const prefill: InitInlineWidgetOptions['prefill'] = {};

          if (queryParameters) {
            for (const { parameter, value } of queryParameters) {
              const paramValue =
                value.type === DataSource.Direct
                  ? value.value
                  : value.name && propsContext[value.name];

              if (queryParamsToKeepInBaseUrl.includes(parameter)) {
                if (isString(paramValue) || isNumber(paramValue)) {
                  // The same parameter could have been provided in the base URL string. In this case we override it.
                  parsedUrl.searchParams.set(parameter, paramValue.toString());
                }
              } else if (isString(paramValue) || isNumber(paramValue)) {
                // This parameter will be passed as prefill. Calendly does not handle values where spaces are encoded with plus signs.
                parsedUrl.searchParams.delete(parameter);
                prefill[parameter] = paramValue.toString();
              }
            }
          }

          const urlWithoutQuery = new URL(parsedUrl);
          urlWithoutQuery.search = '';
          const url = urlWithoutQuery.toString();

          const utm: InitInlineWidgetOptions['utm'] = {};
          const utmSource =
            parsedUrl.searchParams.get('utm_source') || searchParams.get('utm_source');
          if (utmSource) {
            utm.utmSource = utmSource;
          }
          const utmMedium =
            parsedUrl.searchParams.get('utm_medium') || searchParams.get('utm_medium');
          if (utmMedium) {
            utm.utmMedium = utmMedium;
          }
          const utmCampaign =
            parsedUrl.searchParams.get('utm_campaign') || searchParams.get('utm_campaign');
          if (utmCampaign) {
            utm.utmCampaign = utmCampaign;
          }
          const utmContent =
            parsedUrl.searchParams.get('utm_content') || searchParams.get('utm_content');
          if (utmContent) {
            utm.utmContent = utmContent;
          }
          const utmTerm = parsedUrl.searchParams.get('utm_term') || searchParams.get('utm_term');
          if (utmTerm) {
            utm.utmTerm = utmTerm;
          }

          window.Calendly?.initInlineWidget({
            url,
            parentElement,
            prefill,
            utm,
          });

          return () => {
            while (parentElement.firstChild) {
              parentElement.removeChild(parentElement.firstChild);
            }
          };
        } catch {
          // This component will not be rendered.
        }
      }
      initialized.current = true;
    }
    return noop;
  }, [propsContext, queryParameters, searchParams, urlSource]);

  return <div ref={parent} className={componentClassName(props)} />;
};
