import { noop } from 'lodash-es';
import type { FunctionComponent, PropsWithChildren } from 'react';

const { themeColor, grayColor } = window.wirechunk;

const _contextValue = {
  appearance: 'light' as const,
  accentColor: themeColor,
  grayColor,
  resolvedGrayColor: grayColor,
  panelBackground: 'solid' as const,
  radius: 'medium' as const,
  scaling: '100%' as const,
  onAppearanceChange: noop,
  onAccentColorChange: noop,
  onGrayColorChange: noop,
  onPanelBackgroundChange: noop,
  onRadiusChange: noop,
  onScalingChange: noop,
};

export const Theme: FunctionComponent<PropsWithChildren> = ({ children }) => {
  // return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
  return <>{children}</>;
};
