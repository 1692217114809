import { useQuery } from '@apollo/client';
import { setUser as setSentryUser } from '@sentry/browser';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { apolloClient } from '../apollo-client.ts';
import { CurrentUserProvider } from '../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { MeDocument } from '../contexts/CurrentUserContext/queries.generated.ts';
import type { ErrorCollectorContext } from '../contexts/error-collector-context.tsx';
import {
  ErrorCollectorContextProviderDirect,
  useErrorCollector,
} from '../contexts/error-collector-context.tsx';
import type { SessionStatusContext } from '../contexts/SessionStatusContext/SessionStatusContext.ts';
import {
  SessionStatus,
  SessionStatusContextProvider,
} from '../contexts/SessionStatusContext/SessionStatusContext.ts';
import { useErrorHandler } from '../hooks/useErrorHandler.tsx';
import { useRefreshSession } from '../hooks/useRefreshSession/useRefreshSession.ts';
import { triggerGoogleTagManagerEvent } from '../util/analyticsScripts.ts';
import { unauthenticatedErrorHandler } from '../util/errors.ts';
import { authenticateMessenger } from '../util/zendesk.ts';

export const SessionUserProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { onErrorToast } = useErrorHandler();
  const { data: meData, loading } = useQuery(MeDocument, { onError: onErrorToast });
  useRefreshSession();

  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.SignedOut);
  const [sessionErrorMessage, setSessionErrorMessage] = useState<string | null>(null);

  const sessionStatusContext = useMemo<SessionStatusContext>(
    () => ({
      status: sessionStatus,
      setStatus: setSessionStatus,
      errorMessage: sessionErrorMessage,
      setErrorMessage: setSessionErrorMessage,
    }),
    [sessionErrorMessage, sessionStatus],
  );

  // Handle error when a user makes a request when they are not signed in, including when their session has expired.
  const { onError: contextOnError } = useErrorCollector();
  const onError = useMemo<ErrorCollectorContext>(
    () => ({
      onError: (error) => {
        // If we get a non-empty value from offlineErrorHandler, then we still have an error to handle.
        const messageNotOfflineError = unauthenticatedErrorHandler(error, sessionStatusContext);
        if (messageNotOfflineError) {
          contextOnError(messageNotOfflineError);
        }
      },
    }),
    [contextOnError, sessionStatusContext],
  );

  const me = meData?.me || null;

  useEffect(() => {
    if (me) {
      authenticateMessenger(apolloClient);
      triggerGoogleTagManagerEvent({
        user_id: me.id,
      });
      triggerGoogleTagManagerEvent({
        event: 'user_authenticated',
      });
      setSentryUser({
        id: me.id,
        email: me.email,
      });
      setSessionStatus(SessionStatus.Active);
    }
  }, [me]);

  return (
    <SessionStatusContextProvider value={sessionStatusContext}>
      <CurrentUserProvider user={me} loadingUser={loading}>
        <ErrorCollectorContextProviderDirect value={onError}>
          {children}
        </ErrorCollectorContextProviderDirect>
      </CurrentUserProvider>
    </SessionStatusContextProvider>
  );
};
