import type { FunctionComponent, PropsWithChildren } from 'react';
import { useOptionalCurrentUser } from '../contexts/CurrentUserContext/CurrentUserContext.tsx';
import {
  SessionStatus,
  useSessionStatusContext,
} from '../contexts/SessionStatusContext/SessionStatusContext.ts';
import { AuthForm } from './AuthForm/AuthForm.tsx';

export const AuthenticationGuard: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { user, loadingUser } = useOptionalCurrentUser();
  const sessionStatus = useSessionStatusContext();
  if (user && sessionStatus.status === SessionStatus.Active) {
    return children;
  }
  return <AuthForm loadingUser={loadingUser} />;
};
