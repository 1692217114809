import { useQuery } from '@apollo/client';
import type { CourseSubLesson } from '@wirechunk/lib/api.ts';
import { currentDate } from '@wirechunk/lib/dates.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { CourseComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { ComponentType } from '@wirechunk/lib/mixer/types/components.ts';
import { findComponentById, parseComponents } from '@wirechunk/lib/mixer/utils.ts';
import { clsx } from 'clsx';
import { sumBy } from 'lodash-es';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useDialog } from '../../../contexts/DialogContext/DialogContext.tsx';
import type { StageContext } from '../../../contexts/StageContext/StageContext.ts';
import {
  StagesRegistryContextProvider,
  useStagesRegistryContext,
} from '../../../contexts/StagesRegistryContext/stages-registry-context.tsx';
import { useCompletedStagesChanged } from '../../../hooks/useCompletedStagesChanged/useCompletedStagesChanged.ts';
import { useCurrentUserPlan } from '../../../hooks/useCurrentUserPlan/useCurrentUserPlan.ts';
import type { ErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useInterval } from '../../../hooks/useInterval.ts';
import { HeadingAndSubHeading } from '../../HeadingAndSubHeading/HeadingAndSubHeading.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { Stage } from '../../Stage/Stage.tsx';
import styles from './course.module.css';
import { CourseTakeaways } from './CourseTakeaways.tsx';
import type { CourseForUserQuery, CurrentUserPlanQuery } from './queries.generated.ts';
import { CourseForUserDocument, StageBlueprintDocument } from './queries.generated.ts';

export const defaultEmptyLessonNotes =
  'You have not written any notes yet. Notes can be written at the end of each lesson.';

type StageBlueprint = CourseForUserQuery['course']['stageBlueprints'][number];

type LessonBodyProps = {
  planId: string;
  stageBlueprint: StageBlueprint;
  currentSubLessonId: string | undefined;
  userPlan: StageContext['userPlan'];
  onError: ErrorHandler['onError'];
};

const LessonBody: FunctionComponent<LessonBodyProps> = ({
  planId,
  stageBlueprint,
  currentSubLessonId,
  userPlan,
  onError,
}) => {
  const { data, loading } = useQuery(StageBlueprintDocument, {
    onError,
    variables: { id: stageBlueprint.id },
  });

  const renderedStageBlueprint = useMemo<StageContext['stageBlueprint'] | null>(() => {
    if (data) {
      let components = parseComponents(data.stageBlueprint.components);

      if (currentSubLessonId && stageBlueprint.subLessons.length) {
        const subLesson = findComponentById(components, currentSubLessonId);
        if (subLesson?.type === ComponentType.CourseSubSection) {
          components = subLesson.children || [];
        }
      }

      return {
        id: stageBlueprint.id,
        planId,
        name: stageBlueprint.name,
        components,
      };
    }
    return null;
  }, [
    currentSubLessonId,
    data,
    planId,
    stageBlueprint.id,
    stageBlueprint.name,
    stageBlueprint.subLessons.length,
  ]);

  if (loading) {
    return <Spinner py="3" />;
  }

  return (
    renderedStageBlueprint && (
      <Stage
        date={currentDate()}
        stageBlueprint={renderedStageBlueprint}
        userPlan={userPlan}
        onError={onError}
      />
    )
  );
};

type CourseBodyProps = {
  course: CourseForUserQuery['course'];
  enableNotes: boolean;
  emptyNotesMessage: string | null | undefined;
  userPlan: NonNullable<CurrentUserPlanQuery['me']>['plan'];
  userId: string;
  onError: ErrorHandler['onError'];
};

const CourseBody: FunctionComponent<CourseBodyProps> = ({
  course,
  enableNotes,
  emptyNotesMessage,
  userPlan,
  userId,
  onError,
}) => {
  const location = useLocation();
  const dialog = useDialog();
  const stagesRegistry = useStagesRegistryContext();
  const [currentLesson, setCurrentSection] = useState<StageBlueprint | null>(
    // The default lesson comes from the user's current StageBlueprint in their UserPlan.
    () => course.stageBlueprints.find(({ id }) => id === userPlan.stageBlueprint.id) || null,
  );
  const [currentSubLesson, setCurrentSubLesson] = useState<CourseSubLesson | null>(
    currentLesson?.subLessons[0] || null,
  );

  const completedLessonsCount = sumBy(course.stageBlueprints, (s) =>
    s.latestStageCompleted ? 1 : 0,
  );
  const completedPercentage = course.stageBlueprints.length
    ? Math.round((completedLessonsCount * 100) / course.stageBlueprints.length)
    : 0;

  const [_empty, backPathSegment, ...restPathParts] = location.pathname.split('/');
  const backPath = backPathSegment && restPathParts.length ? `/${backPathSegment}` : null;

  if (!stagesRegistry) {
    // This is for TypeScript only. The parent component provides a StagesRegistryContext.
    return null;
  }

  return (
    <div className={styles.layout}>
      <div
        className={`${styles.courseInfoBar} px-3 flex align-items-center justify-content-between py-1 xl:py-0`}
      >
        <div className="flex gap-3 align-items-center">
          {backPath && (
            <Link to={backPath} className="flex align-items-center gap-1">
              <i className={PrimeIcons.ARROW_LEFT} />
              <span className="font-medium text-sm">Back</span>
            </Link>
          )}
          <div className="flex gap-2">
            <div className="font-medium">{course.name}</div>
            <div className="text-color-muted">({completedPercentage}% completed)</div>
          </div>
        </div>
        <div className="flex gap-2 align-items-center">
          {stagesRegistry.isSaving ? (
            <span className="text-sm text-color-muted hidden md:block">Saving&hellip;</span>
          ) : (
            stagesRegistry.allChangesSaved && (
              <span className="text-sm text-color-muted hidden md:block">Changes saved</span>
            )
          )}
          {enableNotes && (
            <Button
              className="p-button-text p-button-sm"
              icon={PrimeIcons.BOOK}
              tooltip="My takeaways"
              tooltipOptions={{ position: 'left' }}
              onClick={() => {
                dialog({
                  content: (
                    <CourseTakeaways
                      userId={userId}
                      plan={course}
                      emptyNotesMessage={emptyNotesMessage || defaultEmptyLessonNotes}
                    />
                  ),
                  props: {
                    header: 'My takeaways',
                    className: 'dialog-width-lg',
                  },
                });
              }}
            />
          )}
        </div>
      </div>
      <div
        className={`${styles.courseNav} flex flex-column xl:border-right-1 h-full max-h-full overflow-y-auto overscroll-behavior-contain`}
      >
        {course.stageBlueprints.map((section, i) => (
          <div
            key={section.id}
            className={clsx(
              'border-top-1',
              i === 0 && 'xl:border-top-none',
              i === course.stageBlueprints.length - 1 &&
                (!section.subLessons.length || currentLesson?.id !== section.id) &&
                'border-bottom-1',
            )}
          >
            <div
              className={clsx(
                'flex align-items-center py-3 pl-3 pr-2',
                currentLesson?.id === section.id && !section.subLessons.length && 'surface-ground',
              )}
            >
              <div className={`${styles.statusIconContainer} flex align-items-center`}>
                <i
                  className={clsx(
                    'text-lg',
                    section.latestStageCompleted
                      ? `${PrimeIcons.CHECK_CIRCLE} text-color-success`
                      : `${PrimeIcons.CIRCLE} text-color-muted`,
                  )}
                />
              </div>
              <button
                className={clsx(
                  'button-not-styled font-medium text-left px-0',
                  currentLesson?.id === section.id ? 'text-color-body' : 'text-color-muted',
                )}
                onClick={() => {
                  setCurrentSection(section);
                  if (
                    !currentSubLesson ||
                    !section.subLessons.some((sl) => sl.id === currentSubLesson.id)
                  ) {
                    setCurrentSubLesson(section.subLessons[0] || null);
                  }
                }}
              >
                {section.name}
              </button>
            </div>
            {currentLesson?.id === section.id && section.subLessons.length > 0 && (
              <div className="flex flex-column">
                {section.subLessons.map((sl) => (
                  <button
                    key={sl.id}
                    className={clsx(
                      styles.subSection,
                      'button-not-styled text-left pr-2 py-2',
                      currentLesson.id === section.id && currentSubLesson?.id === sl.id
                        ? `${styles.currentSubSection} text-color-body`
                        : 'text-color-muted',
                    )}
                    onClick={() => {
                      setCurrentSection(section);
                      setCurrentSubLesson(sl);
                    }}
                  >
                    {sl.heading}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="overflow-auto overscroll-behavior-contain p-4">
        {currentLesson && (
          <Fragment>
            <HeadingAndSubHeading
              heading={currentLesson.name}
              subHeading={currentSubLesson?.heading}
            />
            <LessonBody
              planId={course.id}
              stageBlueprint={currentLesson}
              currentSubLessonId={currentSubLesson?.id}
              userPlan={userPlan}
              onError={onError}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

type GuardedCourseProps = {
  courseId: string;
  enableNotes: boolean;
  emptyNotesMessage: string | null | undefined;
};

const GuardedCourse: FunctionComponent<GuardedCourseProps> = ({
  courseId,
  enableNotes,
  emptyNotesMessage,
}) => {
  const { user } = useCurrentUser();
  const { onError, ErrorMessage } = useErrorHandler();
  const stagesRegistryContext = useStagesRegistryContext();
  const { userPlan, loading: loadingUserPlan } = useCurrentUserPlan(courseId, onError);
  const {
    data: planData,
    loading: loadingPlanLessons,
    refetch: refetchPlan,
  } = useQuery(CourseForUserDocument, {
    onError,
    // This policy is needed to ensure that the plan is re-fetched when the user's stages change.
    fetchPolicy: 'cache-and-network',
    variables: { id: courseId, userId: user.id },
  });

  // We shouldn't pass in refetchPlan directly to useCompletedStagesChanged because it expects arguments that Apollo
  // Client then tries to serialize and send in the request.
  const refetchPlanWrapped = useCallback(() => {
    void refetchPlan();
  }, [refetchPlan]);

  useCompletedStagesChanged(user.id, refetchPlanWrapped, onError);

  // We poll in addition to the subscription because the Websocket that our subscription uses is periodically closed by
  // the load balancer, and if a stage is completed during the period when we don't have a connection, we won't get a
  // notification that completed stages have changed.
  //
  // Also this helps us detect when the user's session has expired so that we can prompt the user to sign in again before
  // they try to make changes that need to be saved.
  useInterval(refetchPlanWrapped, 10_000);

  const course = planData?.course;

  return (
    <Fragment>
      <ErrorMessage />
      {
        // Here it's important to check that course isn't defined because the PlanCourseLessons query is done on an
        // interval and we don't want the screen to flicker when the data is re-fetched.
        (loadingUserPlan || loadingPlanLessons) && !course ? (
          <Spinner py="3" />
        ) : (
          course &&
          userPlan &&
          (stagesRegistryContext ? (
            <CourseBody
              course={course}
              enableNotes={enableNotes}
              emptyNotesMessage={emptyNotesMessage}
              userPlan={userPlan}
              userId={user.id}
              onError={onError}
            />
          ) : (
            <StagesRegistryContextProvider>
              <CourseBody
                course={course}
                enableNotes={enableNotes}
                emptyNotesMessage={emptyNotesMessage}
                userPlan={userPlan}
                userId={user.id}
                onError={onError}
              />
            </StagesRegistryContextProvider>
          ))
        )
      }
    </Fragment>
  );
};

export const Course: FunctionComponent<CourseComponent> = (props) => {
  const { planId } = props;
  if (!planId) {
    return null;
  }

  return (
    <div className={`${componentClassName(props)} flex flex-column h-full max-h-full`}>
      <GuardedCourse
        courseId={planId}
        enableNotes={!!props.enableNotes}
        emptyNotesMessage={props.emptyNotesMessage}
      />
    </div>
  );
};
