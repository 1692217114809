import { breakpoints } from './breakpoints.ts';
import { componentIdClassName } from './component-class-name.ts';
import type { CssProperties } from './cssProperties.ts';
import type { Component, ComponentBase, Styles } from './types/components.ts';
import { ComponentType } from './types/components.ts';
import { componentHasChildren } from './utils.ts';

const propertiesCss = (properties: CssProperties): string =>
  Object.entries(properties).reduce<string>((acc, [key, value]): string => {
    if (value) {
      return `${acc}${key}:${value};`;
    }
    return acc;
  }, '');

const wrapMediaMinWidth = (css: string, minWidth: number): string =>
  `@media screen and (min-width:${minWidth}px){${css}}`;

export const renderStyles = (
  selector: string,
  {
    styles,
    smStyles,
    mdStyles,
    lgStyles,
    xlStyles,
    xxlStyles,
    hoverStyles,
    focusVisibleStyles,
  }: Styles,
): string => {
  let css: string = '';
  if (styles) {
    css += `${selector}{${propertiesCss(styles)}}`;
  }
  if (smStyles) {
    const smCss = propertiesCss(smStyles);
    css += wrapMediaMinWidth(`${selector}{${smCss}}`, breakpoints.sm);
  }
  if (mdStyles) {
    const mdCss = propertiesCss(mdStyles);
    if (mdCss) {
      css += wrapMediaMinWidth(`${selector}{${mdCss}}`, breakpoints.md);
    }
  }
  if (lgStyles) {
    const lgCss = propertiesCss(lgStyles);
    if (lgCss) {
      css += wrapMediaMinWidth(`${selector}{${lgCss}}`, breakpoints.lg);
    }
  }
  if (xlStyles) {
    const xlCss = propertiesCss(xlStyles);
    if (xlCss) {
      css += wrapMediaMinWidth(`${selector}{${xlCss}}`, breakpoints.xl);
    }
  }
  if (xxlStyles) {
    const xxlCss = propertiesCss(xxlStyles);
    if (xxlCss) {
      css += wrapMediaMinWidth(`${selector}{${xxlCss}}`, breakpoints.xxl);
    }
  }

  if (hoverStyles) {
    css += renderStyles(`${selector}:hover`, hoverStyles);
  }

  if (focusVisibleStyles) {
    css += renderStyles(`${selector}:focus-visible`, focusVisibleStyles);
  }

  return css;
};

export const componentIdSelector = (component: ComponentBase<ComponentType>): string =>
  `.${componentIdClassName(component)}`;

export const conditionalStylesAttributeKey = (stylesKey: string): string =>
  `data-styles-${stylesKey}`;

export const conditionalStylesSelector = (
  component: ComponentBase<ComponentType>,
  stylesKey: string,
): string => `${componentIdSelector(component)}[${conditionalStylesAttributeKey(stylesKey)}]`;

export const collectAllCss = (accumulatedCss: string, curr: Component): string => {
  const selector = componentIdSelector(curr);

  let css = accumulatedCss + renderStyles(selector, curr);

  if (curr.labelStyles) {
    const labelSelector =
      curr.type === ComponentType.Button || curr.type === ComponentType.ViewPlanStagesButton
        ? '> .p-button-label'
        : '.input-label';
    css += renderStyles(`${selector} ${labelSelector}`, curr.labelStyles);
  }

  if (curr.conditionalStyles) {
    for (const { key, styles } of curr.conditionalStyles) {
      css += renderStyles(conditionalStylesSelector(curr, key), styles);
    }
  }

  if (componentHasChildren(curr)) {
    return curr.children.reduce(collectAllCss, css);
  }

  return css;
};
