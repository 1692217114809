import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import { ComponentType } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { AgreementsGuard } from './mixer/agreements-guard/agreements-guard.tsx';
import { AppPageTiles } from './mixer/AppPageTiles/AppPageTiles.tsx';
import { BackButton } from './mixer/back-button/back-button.tsx';
import { BooleanInput } from './mixer/BooleanInput/BooleanInput.tsx';
import { Box } from './mixer/Box.tsx';
import { Button } from './mixer/Button.tsx';
import { CalendlyEmbed } from './mixer/calendly-embed/calendly-embed.tsx';
import { CollapsiblePanel } from './mixer/CollapsiblePanel.tsx';
import { CompensationCalculator } from './mixer/CompensationCalculator/CompensationCalculator.tsx';
import { CompletableVideoInput } from './mixer/CompletableVideoInput/CompletableVideoInput.tsx';
import { ConditionalLogic } from './mixer/conditional-logic/conditional-logic.tsx';
import { Course } from './mixer/course/course.tsx';
import { CourseSubSection } from './mixer/CourseSubSection/CourseSubSection.tsx';
import { CurrentYear } from './mixer/CurrentYear.tsx';
import { CustomizeOrganizationSite } from './mixer/CustomizeOrganizationSite/CustomizeOrganizationSite.tsx';
import { Dashboard } from './mixer/dashboard.tsx';
import { DataInputTable } from './mixer/DataInputTable/DataInputTable.tsx';
import { DateInput } from './mixer/DateInput/DateInput.tsx';
import { Design } from './mixer/Design/Design.tsx';
import { Document } from './mixer/document/document.tsx';
import { Resource } from './mixer/document/resource.ts';
import { DropdownInput } from './mixer/DropdownInput/DropdownInput.tsx';
import { Form } from './mixer/form/Form.tsx';
import { FormConfirmationMessage } from './mixer/form-confirmation-message.tsx';
import { FormStep } from './mixer/form-step/form-step.tsx';
import { HelpTicketsPortal } from './mixer/HelpTicketsPortal/HelpTicketsPortal.tsx';
import { HTML } from './mixer/HTML.tsx';
import { Icon } from './mixer/Icon.tsx';
import { Image } from './mixer/Image.tsx';
import { InputValidationErrorMessage } from './mixer/input-validation-error-message/input-validation-error-message.tsx';
import { Link } from './mixer/link.tsx';
import { Map } from './mixer/Map.tsx';
import { MarketingCalculator } from './mixer/MarketingCalculator/MarketingCalculator.tsx';
import { MoveUserPlan } from './mixer/MoveUserPlan/MoveUserPlan.tsx';
import { MultiOrgView } from './mixer/MultiOrgView/MultiOrgView.tsx';
import { MultipleChoiceInput } from './mixer/MultipleChoiceInput/MultipleChoiceInput.tsx';
import { MultiSelectInput } from './mixer/MultiSelectInput/MultiSelectInput.tsx';
import { NumberInput } from './mixer/NumberInput/NumberInput.tsx';
import { OrderPage } from './mixer/order-form/order-form.tsx';
import { OrganizationLogo } from './mixer/organization-logo.ts';
import { OrganizationName } from './mixer/organization-name.ts';
import { OrganizationAgencyAddressCity } from './mixer/OrganizationAgencyAddressCity.tsx';
import { OrganizationAgencyAddressLine1 } from './mixer/OrganizationAgencyAddressLine1.tsx';
import { OrganizationAgencyAddressLine2 } from './mixer/OrganizationAgencyAddressLine2.tsx';
import { OrganizationAgencyAddressState } from './mixer/OrganizationAgencyAddressState.tsx';
import { OrganizationAgencyAddressZip } from './mixer/OrganizationAgencyAddressZip.tsx';
import { OrganizationAgencyPrimaryPhone } from './mixer/OrganizationAgencyPrimaryPhone.tsx';
import { OrganizationAgentFirstName } from './mixer/OrganizationAgentFirstName.tsx';
import { OrganizationAgentFullName } from './mixer/OrganizationAgentFullName.tsx';
import { OrganizationAgentLastName } from './mixer/OrganizationAgentLastName.tsx';
import { OrganizationBilling } from './mixer/OrganizationBilling/OrganizationBilling.tsx';
import { OrganizationSiteForms } from './mixer/OrganizationSiteForms/OrganizationSiteForms.tsx';
import { OrganizationSiteLeads } from './mixer/OrganizationSiteLeads/OrganizationSiteLeads.tsx';
import { OrganizationSiteReviews } from './mixer/OrganizationSiteReviews/OrganizationSiteReviews.tsx';
import { OrganizationTeam } from './mixer/OrganizationTeam/OrganizationTeam.tsx';
import { Padding } from './mixer/padding/padding.tsx';
import { Page } from './mixer/page.tsx';
import { PhoneLink } from './mixer/PhoneLink.tsx';
import { PlainText } from './mixer/PlainText.tsx';
import { Popover } from './mixer/Popover/Popover.tsx';
import { ProvideProps } from './mixer/provide-props/provide-props.tsx';
import { RadioGroupInput } from './mixer/radio-group-input/radio-group-input.tsx';
import { RadioGroupInputButton } from './mixer/radio-group-input-button/radio-group-input-button.tsx';
import { RequestPasswordReset } from './mixer/RequestPasswordReset/RequestPasswordReset.tsx';
import { ResetPassword } from './mixer/ResetPassword/ResetPassword.tsx';
import { Review } from './mixer/Review/Review.tsx';
import { ScoreMyCall } from './mixer/ScoreMyCall/ScoreMyCall.tsx';
import { SetUpSiteGuard } from './mixer/SetUpSiteGuard/SetUpSiteGuard.tsx';
import { SignInGuard } from './mixer/sign-in-guard.tsx';
import { SiteLogo } from './mixer/site-logo.tsx';
import { SiteName } from './mixer/site-name.tsx';
import { SiteLandingPages } from './mixer/SiteLandingPages/SiteLandingPages.tsx';
import { OrganizationPrivacyPolicy } from './mixer/SitePrivacyPolicy/organization-privacy-policy.ts';
import { SitePrivacyPolicy } from './mixer/SitePrivacyPolicy/site-privacy-policy.tsx';
import { OrganizationTermsOfUse } from './mixer/SiteTermsOfUse/organization-terms-of-use.ts';
import { SiteTermsOfUse } from './mixer/SiteTermsOfUse/site-terms-of-use.tsx';
import { Stage } from './mixer/Stage/Stage.tsx';
import { StageBody } from './mixer/StageBody/StageBody.tsx';
import { StageFileInput } from './mixer/StageFileInput/StageFileInput.tsx';
import { StageName } from './mixer/StageName/StageName.tsx';
import { SubscriptionTagGuard } from './mixer/SubscriptionTagGuard/SubscriptionTagGuard.tsx';
import { Text } from './mixer/Text.tsx';
import { TextareaInput } from './mixer/TextareaInput.tsx';
import { TextInput } from './mixer/TextInput/TextInput.tsx';
import { Typeform } from './mixer/Typeform/Typeform.tsx';
import { UserProfile } from './mixer/UserProfile/UserProfile.tsx';
import { VerifiedEmailAddressGuard } from './mixer/VerifiedEmailAddressGuard/VerifiedEmailAddressGuard.tsx';
import { VerifyEmailAddress } from './mixer/VerifyEmailAddress/VerifyEmailAddress.tsx';
import { Video } from './mixer/Video.tsx';
import { ViewPlanStagesButton } from './mixer/ViewPlanStagesButton/ViewPlanStagesButton.tsx';
import { BodyContainer } from './mixer/width-container/BodyContainer.tsx';
import { WidthContainer } from './mixer/width-container/width-container.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentByType = (type: ComponentType): FunctionComponent<any> | undefined => {
  switch (type) {
    case ComponentType.AgreementsGuard:
      return AgreementsGuard;
    case ComponentType.AppPageTiles:
      return AppPageTiles;
    case ComponentType.BackButton:
      return BackButton;
    case ComponentType.BodyContainer:
      return BodyContainer;
    case ComponentType.BooleanInput:
      return BooleanInput;
    case ComponentType.Box:
      return Box;
    case ComponentType.Button:
      return Button;
    case ComponentType.CalendlyEmbed:
      return CalendlyEmbed;
    case ComponentType.CollapsiblePanel:
      return CollapsiblePanel;
    case ComponentType.CompensationCalculator:
      return CompensationCalculator;
    case ComponentType.CompletableVideoInput:
      return CompletableVideoInput;
    case ComponentType.ConditionalLogic:
      return ConditionalLogic;
    case ComponentType.Course:
      return Course;
    case ComponentType.CourseSubSection:
      return CourseSubSection;
    case ComponentType.CurrentYear:
      return CurrentYear;
    case ComponentType.CustomizeOrganizationSite:
      return CustomizeOrganizationSite;
    case ComponentType.Dashboard:
      return Dashboard;
    case ComponentType.DataInputTable:
      return DataInputTable;
    case ComponentType.DateInput:
      return DateInput;
    case ComponentType.Design:
      return Design;
    case ComponentType.Document:
      return Document;
    case ComponentType.DropdownInput:
      return DropdownInput;
    case ComponentType.Form:
      return Form;
    case ComponentType.FormConfirmationMessage:
      return FormConfirmationMessage;
    case ComponentType.FormStep:
      return FormStep;
    case ComponentType.HelpTicketsPortal:
      return HelpTicketsPortal;
    case ComponentType.HTML:
      return HTML;
    case ComponentType.Icon:
      return Icon;
    case ComponentType.Image:
      return Image;
    case ComponentType.InputValidationErrorMessage:
      return InputValidationErrorMessage;
    case ComponentType.Link:
      return Link;
    case ComponentType.Map:
      return Map;
    case ComponentType.MarketingCalculator:
      return MarketingCalculator;
    case ComponentType.MoveUserPlan:
      return MoveUserPlan;
    case ComponentType.MultiOrgView:
      return MultiOrgView;
    case ComponentType.MultipleChoiceInput:
      return MultipleChoiceInput;
    case ComponentType.MultiSelectInput:
      return MultiSelectInput;
    case ComponentType.NumberInput:
      return NumberInput;
    case ComponentType.OrderPage:
      return OrderPage;
    case ComponentType.OrganizationAgencyAddressLine1:
      return OrganizationAgencyAddressLine1;
    case ComponentType.OrganizationAgencyAddressLine2:
      return OrganizationAgencyAddressLine2;
    case ComponentType.OrganizationAgencyAddressCity:
      return OrganizationAgencyAddressCity;
    case ComponentType.OrganizationAgencyAddressState:
      return OrganizationAgencyAddressState;
    case ComponentType.OrganizationAgencyAddressZip:
      return OrganizationAgencyAddressZip;
    case ComponentType.OrganizationAgencyPrimaryPhone:
      return OrganizationAgencyPrimaryPhone;
    case ComponentType.OrganizationAgentFirstName:
      return OrganizationAgentFirstName;
    case ComponentType.OrganizationAgentFullName:
      return OrganizationAgentFullName;
    case ComponentType.OrganizationAgentLastName:
      return OrganizationAgentLastName;
    case ComponentType.OrganizationBilling:
      return OrganizationBilling;
    case ComponentType.OrganizationLogo:
      return OrganizationLogo;
    case ComponentType.OrganizationName:
      return OrganizationName;
    case ComponentType.OrganizationSiteForms:
      return OrganizationSiteForms;
    case ComponentType.OrganizationSiteLeads:
      return OrganizationSiteLeads;
    case ComponentType.OrganizationSiteReviews:
      return OrganizationSiteReviews;
    case ComponentType.OrganizationTeam:
      return OrganizationTeam;
    case ComponentType.OrganizationTermsOfUse:
      return OrganizationTermsOfUse;
    case ComponentType.OrganizationPrivacyPolicy:
      return OrganizationPrivacyPolicy;
    case ComponentType.Padding:
      return Padding;
    case ComponentType.Page:
      return Page;
    case ComponentType.PhoneLink:
      return PhoneLink;
    case ComponentType.PlainText:
      return PlainText;
    case ComponentType.ProvideProps:
      return ProvideProps;
    case ComponentType.Popover:
      return Popover;
    case ComponentType.RadioGroupInput:
      return RadioGroupInput;
    case ComponentType.RadioGroupInputButton:
      return RadioGroupInputButton;
    case ComponentType.RequestPasswordReset:
      return RequestPasswordReset;
    case ComponentType.ResetPassword:
      return ResetPassword;
    case ComponentType.Resource:
      return Resource;
    case ComponentType.Review:
      return Review;
    case ComponentType.SetUpSiteGuard:
      return SetUpSiteGuard;
    case ComponentType.ScoreMyCall:
      return ScoreMyCall;
    case ComponentType.SignInGuard:
      return SignInGuard;
    case ComponentType.SiteLandingPages:
      return SiteLandingPages;
    case ComponentType.SiteLogo:
      return SiteLogo;
    case ComponentType.SiteName:
      return SiteName;
    case ComponentType.SitePrivacyPolicy:
      return SitePrivacyPolicy;
    case ComponentType.SiteTermsOfUse:
      return SiteTermsOfUse;
    case ComponentType.Stage:
      return Stage;
    case ComponentType.StageBody:
      return StageBody;
    case ComponentType.StageFileInput:
      return StageFileInput;
    case ComponentType.StageName:
      return StageName;
    case ComponentType.SubscriptionTagGuard:
      return SubscriptionTagGuard;
    case ComponentType.Text:
      return Text;
    case ComponentType.TextInput:
      return TextInput;
    case ComponentType.TextareaInput:
      return TextareaInput;
    case ComponentType.Typeform:
      return Typeform;
    case ComponentType.UserProfile:
      return UserProfile;
    case ComponentType.VerifiedEmailAddressGuard:
      return VerifiedEmailAddressGuard;
    case ComponentType.VerifyEmailAddress:
      return VerifyEmailAddress;
    case ComponentType.Video:
      return Video;
    case ComponentType.ViewPlanStagesButton:
      return ViewPlanStagesButton;
    case ComponentType.WidthContainer:
      return WidthContainer;
    default:
      return undefined;
  }
};

type RenderComponentProps = Component;

export const RenderComponent: FunctionComponent<RenderComponentProps> = (props) => {
  const InnerComponent = componentByType(props.type);
  if (InnerComponent) {
    return <InnerComponent {...props} />;
  }

  return null;
};
