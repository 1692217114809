import { useQuery } from '@apollo/client';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { Fragment } from 'react';
import { useErrorCollector } from '../../contexts/error-collector-context.tsx';
import { SiteContextProvider } from '../../contexts/SiteContext/SiteContext.tsx';
import { Spinner } from '../spinner/spinner.tsx';
import { CurrentSiteDocument } from './queries.generated.ts';

export const CurrentSiteProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { onError } = useErrorCollector();
  const { data, loading } = useQuery(CurrentSiteDocument, {
    onError,
  });

  const site = data?.currentSite;

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : site ? (
        <SiteContextProvider value={site}>{children}</SiteContextProvider>
      ) : (
        // This div has mt-6 so that this message is visible even if a global error is shown at the top.
        <div className="mt-6 px-3 font-bold">No website was found here!</div>
      )}
    </Fragment>
  );
};
