import { useApolloClient, useMutation } from '@apollo/client';
import { setUser as setSentryUser } from '@sentry/browser';
import { OrganizationPermission } from '@wirechunk/lib/api.ts';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import type { FunctionComponent } from 'react';
import { Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../../contexts/CurrentUserContext/CurrentUserContext.tsx';
import { useProductAnalytics } from '../../../contexts/ProductAnalyticsContext/ProductAnalyticsContext.tsx';
import {
  SessionStatus,
  useSessionStatusContext,
} from '../../../contexts/SessionStatusContext/SessionStatusContext.ts';
import { useToast } from '../../../contexts/ToastContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useSendVerificationEmail } from '../../../hooks/useSendVerificationEmail/useSendVerificationEmail.ts';
import { signOutMessenger } from '../../../util/zendesk.ts';
import { Logo } from '../../Logo.tsx';
import { SignOutDocument } from './mutations.generated.ts';
import styles from './toolbar.module.css';

export type ToolbarProps = {
  className?: string;
  onShowSidebar: () => void;
  LeftElements?: FunctionComponent;
};

export const Toolbar: FunctionComponent<ToolbarProps> = ({
  className,
  onShowSidebar,
  LeftElements,
}) => {
  const { toastSuccess } = useToast();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const sessionStatus = useSessionStatusContext();
  const userMenu = useRef<Menu>(null);
  const apiClient = useApolloClient();
  const { reset: resetAnalyticsIdentity } = useProductAnalytics();
  const { onErrorToast } = useErrorHandler();
  const [signOut, { loading: isSigningOut }] = useMutation(SignOutDocument, {
    onError: onErrorToast,
    onCompleted: () => {
      void apiClient.resetStore();
      resetAnalyticsIdentity();
      signOutMessenger();
      setSentryUser(null);
    },
  });
  const { sendVerificationEmail, isSending } = useSendVerificationEmail(onErrorToast, () => {
    toastSuccess('Please check your inbox for the verification email.', 'Email sent');
  });

  return (
    <header className={clsx(className, styles.toolbar)}>
      <div className="flex align-items-center flex-grow-1">
        <button
          className={`${styles.sidebarOpenButton} border-none p-button-text`}
          onClick={onShowSidebar}
        >
          <i className={PrimeIcons.BARS} />
        </button>
        <div className="flex align-items-center mr-2 md:mr-3">
          <Logo />
        </div>
        {LeftElements && <LeftElements />}
      </div>
      {isSigningOut ? (
        <div className="text-sm text-color-muted">Signing out...</div>
      ) : (
        <Fragment>
          <Button
            className={`${styles.userButton} p-overlay-badge overflow-visible`}
            onClick={(evt) => {
              userMenu.current?.show(evt);
            }}
          >
            <Avatar
              className={styles.userPhoto}
              icon="pi pi-user"
              // icon={user.photoUrl ? undefined : 'pi pi-user'}
              // image={user.photoUrl || undefined}
              shape="circle"
            />
            {!user.isEmailVerified && (
              <Badge
                className="background-danger color-white text-white font-medium"
                value="1"
                style={{ fontSize: '11px' }}
              />
            )}
          </Button>
          <Menu
            model={[
              {
                template: (
                  <div className={styles.userProfileMenuOption}>
                    <div>
                      <Avatar
                        className={styles.userPhoto}
                        icon="pi pi-user"
                        // icon={user.photoUrl ? undefined : 'pi pi-user'}
                        // image={user.photoUrl || undefined}
                        size="xlarge"
                        shape="circle"
                      />
                    </div>
                    <div className="mt-1 font-medium">{user.displayName}</div>
                    <div className="mt-1 mb-2 pl-1 pr-1 text-sm text-overflow-ellipsis overflow-x-hidden">
                      {user.email}
                    </div>
                    {!user.isEmailVerified && (
                      <Fragment>
                        <div className="mb-1 text-sm font-italic">
                          Your email is not verified yet
                        </div>
                        <Button
                          label="Send verification email"
                          className="p-button-sm font-medium mb-2 block"
                          onClick={(evt) => {
                            void sendVerificationEmail();
                            const { current: menu } = userMenu;
                            if (menu) {
                              menu.hide(evt);
                            }
                          }}
                          disabled={isSending}
                        />
                      </Fragment>
                    )}
                  </div>
                ),
              },
              {
                label: 'My profile',
                icon: 'pi pi-user',
                command: () => {
                  navigate('/profile');
                },
              },
              ...(user.organizationPermissions.includes(OrganizationPermission.Edit)
                ? [
                    {
                      label: 'Billing',
                      icon: 'pi pi-credit-card',
                      command: () => {
                        navigate('/billing');
                      },
                    },
                  ]
                : []),
              {
                label: 'Sign out',
                icon: 'pi pi-sign-out',
                command: () => {
                  // This needs to be updated here so that queries created in components that require authentication
                  // don't get refetched.
                  sessionStatus.setStatus(SessionStatus.SignedOut);
                  void signOut();
                },
              },
            ]}
            ref={userMenu}
            popup
          />
        </Fragment>
      )}
    </header>
  );
};
