import { Column } from 'primereact/column';
import type { DataTableExpandedRows } from 'primereact/datatable';
import { DataTable } from 'primereact/datatable';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { UserTrainingDetails } from '../../UserTrainingDetails/UserTrainingDetails.tsx';
import type { MultiOrgViewQuery } from './queries.generated.ts';

type Agency = MultiOrgViewQuery['multiOrgView'][number];

type Member = Agency['members'][number];

type OrgDetailsProps = {
  agency: Agency;
  contentPlanIdsToShowProgress: string[] | null;
};

export const OrgDetails: FunctionComponent<OrgDetailsProps> = ({
  agency,
  contentPlanIdsToShowProgress,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | any[]>({});

  return (
    <div>
      {agency.primaryUser && (
        <div className="font-medium mb-3">{agency.primaryUser.displayName}’s agency</div>
      )}
      <DataTable
        dataKey="id"
        value={agency.members}
        expandedRows={expandedRows}
        rowExpansionTemplate={
          contentPlanIdsToShowProgress
            ? (row) => (
                <UserTrainingDetails
                  userId={row.id}
                  contentPlanIdsToShowProgress={contentPlanIdsToShowProgress}
                />
              )
            : undefined
        }
        onRowToggle={(e) => {
          setExpandedRows(e.data);
        }}
      >
        <Column expander className="table-left-action-column" />
        <Column
          header="Members"
          body={(member: Member) => (
            <div className="flex flex-column gap-1">
              <span>{member.displayName}</span>
              <span className="text-sm text-color-muted">{member.email}</span>
            </div>
          )}
        />
      </DataTable>
    </div>
  );
};
