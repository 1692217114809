import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { TextInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { TextInputComponentFormat } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { stringOrDefaultEmpty } from '@wirechunk/lib/strings.ts';
import { clsx } from 'clsx';
import { isString } from 'lodash-es';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import type { FunctionComponent, ReactNode } from 'react';
import { memo } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';

type TextInputProps = TextInputComponent & {
  styleAsTableCell?: boolean;
};

const GuardedTextInput: FunctionComponent<ValidInputComponent<TextInputProps>> = (props) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const inputId = useInputId(props);

  const validationError = getValidationError(props);

  const inputValue = stringOrDefaultEmpty(getValue(props));

  const className = clsx('w-full', validationError && 'p-invalid');

  let input: ReactNode;
  if (props.format === TextInputComponentFormat.Phone) {
    input = (
      <InputMask
        id={inputId}
        className={className}
        placeholder={props.placeholder || undefined}
        maxLength={props.maxLength || undefined}
        mask="(999) 999-9999"
        value={inputValue}
        onChange={(evt) => {
          const { value } = evt.target;
          if (isString(value)) {
            setValue(props, value);
          }
        }}
      />
    );
  } else if (props.format === TextInputComponentFormat.Email) {
    input = (
      <InputText
        type="email"
        id={inputId}
        className={className}
        placeholder={props.placeholder || undefined}
        maxLength={props.maxLength || undefined}
        value={inputValue}
        onChange={(evt) => {
          setValue(props, evt.target.value);
        }}
      />
    );
  } else {
    input = (
      <InputText
        id={inputId}
        className={className}
        placeholder={props.placeholder || undefined}
        maxLength={props.maxLength || undefined}
        value={inputValue}
        onChange={(evt) => {
          setValue(props, evt.target.value);
        }}
      />
    );
  }

  return (
    <div className={componentClassName(props)}>
      {props.label && (
        <label className="input-label block" htmlFor={inputId}>
          {props.label}
        </label>
      )}
      {input}
      {validationError && (
        <InputNotice severity={NoticeSeverity.Error}>{validationError}</InputNotice>
      )}
    </div>
  );
};

export const TextInput = memo(withValidInputComponent<TextInputProps>(GuardedTextInput));
