import type { ApolloError } from '@apollo/client';
import type { MessagesMessage } from 'primereact/messages';
import { Messages } from 'primereact/messages';
import type { ReactElement, ReactNode } from 'react';
import { useCallback, useRef } from 'react';
import { useSessionStatusContext } from '../contexts/SessionStatusContext/SessionStatusContext.ts';
import { useToast } from '../contexts/ToastContext.tsx';
import {
  offlineErrorHandler,
  parseWebErrorMessage,
  unauthenticatedErrorHandler,
} from '../util/errors.ts';

const scrollIntoViewIfNeeded = (element: HTMLElement): void => {
  const rect = element.getBoundingClientRect();
  if (rect.top < -1 || rect.bottom > window.innerHeight) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

// messageWithDefaults handles an error message as a string or any React node.
export const messageWithDefaults = (error: string | ReactNode): MessagesMessage => ({
  severity: 'error',
  content: <div>{error}</div>,
  sticky: true,
});

export type ErrorHandlerOptions = {
  // noScrollToMessage defaults to false. This should be set to true whenever error messages are shown in a dialog.
  noScrollToMessage?: boolean;
};

export type ErrorHandler = {
  // onError handles and displays any error in an inline Message.
  // For network requests that failed because we're offline, a toast is shown.
  onError: (error: ApolloError | Error | string) => void;
  // Currently heading is unused, but it's part of the type to help detect misuses in Apollo Client hooks.
  // TODO: Display the heading.
  onErrorWithHeading: (error: ApolloError | Error | string, heading?: string) => void;
  // onErrorToast handles and displays any error in a toast.
  onErrorToast: (error: ApolloError | Error | string) => void;
  onErrorToastWithHeading: (error: ApolloError | Error | string, heading?: string) => void;
  // clearMessages clears all messages (rendered within the body) but not toasts.
  clearMessages: () => void;
  // ErrorMessage renders all error messages that have not expired.
  ErrorMessage: () => ReactElement;
};

export const useErrorHandler = ({ noScrollToMessage }: ErrorHandlerOptions = {}): ErrorHandler => {
  const ref = useRef<Messages>(null);
  const { toastError } = useToast();
  const sessionContext = useSessionStatusContext();

  const ErrorMessage = useCallback<ErrorHandler['ErrorMessage']>(
    () => <Messages className="wrap-overflow-text" ref={ref} />,
    [],
  );

  // This setup with a separate "WithHeading" function is needed to make it ergonomic to use o
  const onErrorWithHeading = useCallback<ErrorHandler['onErrorWithHeading']>(
    (error, _heading) => {
      const messageNotOfflineError = offlineErrorHandler(error, toastError);
      if (messageNotOfflineError) {
        const messageNotUnauthenticated = unauthenticatedErrorHandler(error, sessionContext);
        if (messageNotUnauthenticated) {
          const message = parseWebErrorMessage(messageNotUnauthenticated);
          if (ref.current) {
            ref.current.show(messageWithDefaults(message));
            if (!noScrollToMessage) {
              scrollIntoViewIfNeeded(ref.current.getElement());
            }
          } else {
            setTimeout(() => {
              if (ref.current) {
                ref.current.show(messageWithDefaults(message));
                if (!noScrollToMessage) {
                  scrollIntoViewIfNeeded(ref.current.getElement());
                }
              }
            }, 0);
          }
        }
      }
    },
    [noScrollToMessage, sessionContext, toastError],
  );

  const onError = useCallback<ErrorHandler['onError']>(
    (error) => {
      onErrorWithHeading(error);
    },
    [onErrorWithHeading],
  );

  const onErrorToastWithHeading = useCallback<ErrorHandler['onErrorToastWithHeading']>(
    (error, heading) => {
      const messageNotOfflineError = offlineErrorHandler(error, toastError);
      if (messageNotOfflineError) {
        const messageNotUnauthenticated = unauthenticatedErrorHandler(error, sessionContext);
        if (messageNotUnauthenticated) {
          toastError(parseWebErrorMessage(messageNotUnauthenticated), heading);
        }
      }
    },
    [sessionContext, toastError],
  );

  const onErrorToast = useCallback<ErrorHandler['onErrorToast']>(
    (error) => {
      onErrorToastWithHeading(error);
    },
    [onErrorToastWithHeading],
  );

  const clearMessages = useCallback(() => {
    if (ref.current) {
      ref.current.clear();
    } else {
      setTimeout(() => {
        if (ref.current) {
          ref.current.clear();
        }
      }, 0);
    }
  }, []);

  return {
    onError,
    onErrorWithHeading,
    onErrorToast,
    onErrorToastWithHeading,
    clearMessages,
    ErrorMessage,
  };
};
