import { Flex } from '@radix-ui/themes';
import type { FlexProps } from '@radix-ui/themes';
import { SvgProgressActivity } from '@wirechunk/material-symbols-react-400/20/outlined/progress-activity.tsx';
import type { FunctionComponent } from 'react';
import { forwardRef } from 'react';
import styles from './spinner.module.css';

export const Spinner: FunctionComponent<FlexProps> = forwardRef<HTMLDivElement, FlexProps>(
  (props, ref) => (
    <Flex ref={ref} className={styles.spin} justify="center" align="center" {...props}>
      <SvgProgressActivity />
    </Flex>
  ),
);
